import produce from "immer";
import { get, map, values } from "lodash-es";
import { EstimateEntity as BaseEstimateEntity, UpdateEstimateDTO } from "api";
import { WithId, setNested } from "core";
import { createSelector } from "reselect";

import {
  ActionType,
  createAction,
  createAsyncAction,
  getType
} from "typesafe-actions";
import {
  ActivityFormulaDto,
  ActivityTotal,
  Estimate,
  UniqueEstimateFilters,
  QuoteFolderDto
} from "api/GeneratedClients/insights";
import { AxiosError } from "axios";
import {
  EstimateToFormulaDict,
  mapActivityTotalsBackToFormulas,
  normalizeEstimateDates
} from "./utils";
import {
  selectors as schemaSelectors,
  SelectorState as SchemaStateSlice
} from "modules/schemas";
import {
  selectors as viewSelectors,
  SelectorState as ViewStateSlice
} from "modules/views";
import { selectors as projectSelectors } from "modules/projects";
import { filterField } from "modules/schemas/filters";

interface CustomTotal {
  expression: string;
  unit: string;
  totals: Record<string, number>;
}
interface EstimateWithCustomTotals extends Estimate {
  customTotals?: Record<string, CustomTotal>;
}

interface EstimateEntity extends BaseEstimateEntity {
  values: EstimateWithCustomTotals;
}
export interface HeavybidDivision {
  partitionId: string;
  code: string;
  description: string;
}
export interface SystemBackup {
  partitionId: string;
  name: string;
  lastProcessed?: string | null;
}

interface CodebooksActivity {
  id: string;
  code: string;
  description: string;
  units: string;
  partitionId: string;
}

export interface UnusedActivity extends CodebooksActivity {
  level: string;
  lastUpdate?: Date;
}

export interface MissingActivity extends CodebooksActivity {
  estimate: string;
  biditem: string;
  lastModified?: Date;
}
export interface UtilizedActivityCodebookCode {
  id: string;
  code: string;
  description: string;
  level: string;
  timesModified: number;
  timesUsed: number;
  partitionId: string;
}
export interface UtilizedActivityCodebookCodeDetail {
  code: string;
  modification: string;
  codebookValue: string;
  modifiedValue: string;
  biditem: string;
  estimate: string;
  lastModified?: string;
}

export interface ModificationType {
  [key: string]: string;
}
export interface ActivityCodebooksOverflowStatus {
  unusedOverflow?: boolean;
  utilizedOverflow?: boolean;
  missingOverflow?: boolean;
}
export const ModificationTypes: ModificationType = {
  Units: "Units",
  Crew: "Crew",
  ProdType: "Production Type",
  ProdRate: "Production Rate",
  WorkersCompCode: "WC",
  ReportGroup1: "Report Group 1",
  ReportGroup2: "Report Group 2",
  ReportGroup3: "Report Group 3",
  ReportGroup4: "Report Group 4",
  ReportGroup5: "Report Group 5",
  ReportGroup6: "Report Group 6"
};

export interface DatabasePartition {
  businessUnitCode: string;
  businessUnitId: string;
  divisionCode: string;
  divisionId: string;
  partitionId: string;
  systemId: string;
  regCode: string;
  description: string;
}

export const STATE_KEY = "estimates";

export enum LoadEstimateErrorStatus {
  None,
  MissingDivToBUMapping,
  Unauthorized
}

export interface ActivityCodebooksOverflowStatus {
  unusedOverflow?: boolean;
  utilizedOverflow?: boolean;
  missingOverflow?: boolean;
}

// Models
export interface State {
  allIds: string[];
  workingCopy: { [key: string]: WithId<EstimateEntity> };
  original: { [key: string]: WithId<EstimateEntity> };
  loading: boolean;
  loaded: boolean;
  allLoaded: boolean;
  quoteFolders: {
    [key: string]: QuoteFolderDto;
  };
  activityTotals: Record<string, EstimateToFormulaDict>;
  quoteFoldersLoaded: boolean;
  activityTotalsLoaded: boolean;
  loadEstimateErrorStatus: LoadEstimateErrorStatus;
  hiddenUnlinkedEstimateIds: string[];
  hiddenUnlinkedEstimateIdsLoaded: boolean;
  heavybidDivisions: HeavybidDivision[];
  heavybidDivisionsLoaded: boolean;
  activityCodebookUtilized: Record<string, UtilizedActivityCodebookCode>;
  activityCodebookUtilizedLoaded: boolean;
  activityCodebookUtilizedDetails: UtilizedActivityCodebookCodeDetail[];
  activityCodebookUtilizedDetailsLoaded: boolean;
  activityCodebookUnused: UnusedActivity[];
  activityCodebookUnusedLoaded: boolean;
  activityCodebookMissing: MissingActivity[];
  activityCodebookMissingLoaded: boolean;
  activityCodebooksOverflowStatus: ActivityCodebooksOverflowStatus;
  lastProcessedActivityCodebooks: SystemBackup[];
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadEstimates: createAsyncAction(
    "ESTIMATES/LOAD_REQUEST",
    "ESTIMATES/LOAD_SUCCESS",
    "ESTIMATES/LOAD_FAILURE"
  )<void, WithId<EstimateEntity>[], AxiosError>(),

  loadHeavybidDivisions: createAsyncAction(
    "ESTIMATES/LOAD_HBDIVISIONS_REQUEST",
    "ESTIMATES/LOAD_HBDIVISIONS_SUCCESS",
    "ESTIMATES/LOAD_HBDIVISIONS_FAILURE"
  )<void, DatabasePartition[], AxiosError>(),

  loadLastProcessedActivityCodebooks: createAsyncAction(
    "ESTIMATES/LOAD_LASTPROCESSEDACTIVITYCODEBOOKS_REQUEST",
    "ESTIMATES/LOAD_LASTPROCESSEDACTIVITYCODEBOOKS_SUCCESS",
    "ESTIMATES/LOAD_LASTPROCESSEDACTIVITYCODEBOOKS_FAILURE"
  )<void, SystemBackup[], AxiosError>(),

  loadActivityCodebookUnused: createAsyncAction(
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUNUSED_REQUEST",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUNUSED_SUCCESS",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUNUSED_FAILURE"
  )<void, UnusedActivity[], { axiosError?: AxiosError; overflow?: boolean }>(),

  loadActivityCodebookMissing: createAsyncAction(
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKMISSING_REQUEST",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKMISSING_SUCCESS",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKMISSING_FAILURE"
  )<void, MissingActivity[], { axiosError?: AxiosError; overflow?: boolean }>(),

  loadActivityCodebookUtilized: createAsyncAction(
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUTILIZED_REQUEST",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUTILIZED_SUCCESS",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUTILIZED_FAILURE"
  )<
    void,
    UtilizedActivityCodebookCode[],
    { axiosError?: AxiosError; overflow?: boolean }
  >(),

  loadActivityCodebookUtilizedDetails: createAsyncAction(
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUTILIZEDDETAILS_REQUEST",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUTILIZEDDETAILS_SUCCESS",
    "ESTIMATES/LOAD_ACTIVITYCODEBOOKUTILIZEDDETAILS_FAILURE"
  )<
    { partitionId: string; activityCode: string },
    UtilizedActivityCodebookCodeDetail[],
    AxiosError
  >(),

  loadQuoteFolders: createAsyncAction(
    "ESTIMATES/LOAD_QUOTEFOLDERS_REQUEST",
    "ESTIMATES/LOAD_QUOTEFOLDERS_SUCCESS",
    "ESTIMATES/LOAD_QUOTEFOLDERS_FAILURE"
  )<void, QuoteFolderDto[], AxiosError>(),

  loadEstimateFilters: createAsyncAction(
    "ESTIMATES/LOAD_FILTERS_REQUEST",
    "ESTIMATES/LOAD_FILTERS_SUCCESS",
    "ESTIMATES/LOAD_FILTERS_FAILURE"
  )<void, UniqueEstimateFilters, AxiosError>(),

  loadUserDefinedLabels: createAsyncAction(
    "ESTIMATES/LOAD_USERDEFINEDLABELS_REQUEST",
    "ESTIMATES/LOAD_USERDEFINEDLABELS_SUCCESS",
    "ESTIMATES/LOAD_USERDEFINEDLABELS_FAILURE"
  )<void, Record<string, string>, AxiosError>(),

  loadActivityTotals: createAsyncAction(
    "ESTIMATES/LOAD_TOTALS_ACTIVITY_REQUEST",
    "ESTIMATES/LOAD_TOTALS_ACTIVITY_SUCCESS",
    "ESTIMATES/LOAD_TOTALS_ACTIVITY_FAILURE"
  )<void, [ActivityTotal[], ActivityFormulaDto[]], AxiosError>(),

  saveEstimate: createAsyncAction(
    "ESTIMATES/SAVE_REQUEST",
    "ESTIMATES/SAVE_SUCCESS",
    "ESTIMATES/SAVE_FAILURE"
  )<
    WithId<Partial<EstimateEntity>> & { code: string },
    WithId<EstimateEntity>,
    { id: string; error: AxiosError }
  >(),

  loadedAllEstimates: createAction("ESTIMATES/LOAD_ALL_COMPLETE", resolve => {
    return () => resolve();
  }),

  updateEstimates: createAsyncAction(
    "ESTIMATES/UPDATE_REQUEST",
    "ESTIMATES/UPDATE_SUCESS",
    "ESTIMATES/UPDATE_FAILURE"
  )<UpdateEstimateDTO[], WithId<EstimateEntity>[], AxiosError>(),

  setLoading: createAction("ESTIMATES/SET_LOADING", resolve => {
    return (loadingState: { loaded?: boolean; loading?: boolean }) =>
      resolve({ loadingState });
  }),

  updateHiddenEstimateIds: createAction(
    "ESTIMATES/UPDATE_HIDDEN_ESTIMATE_IDS",
    resolve => {
      return (ids: string[]) => resolve({ ids });
    }
  ),

  loadHiddenUnlinkedEstimateIds: createAsyncAction(
    "ESTIMATES/LOAD_HIDDEN_UNLINKED_ESTIMATES_REQUEST",
    "ESTIMATES/LOAD_HIDDEN_UNLINKED_ESTIMATES_SUCCESS",
    "ESTIMATES/LOAD_HIDDEN_UNLINKED_ESTIMATES_FAILURE"
  )<void, string[], AxiosError>(),

  patchHiddenUnlinkedEstimateIds: createAsyncAction(
    "ESTIMATES/PATCH_HIDDEN_UNLINKED_ESTIMATES_REQUEST",
    "ESTIMATES/PATCH_HIDDEN_UNLINKED_ESTIMATES_SUCCESS",
    "ESTIMATES/PATCH_HIDDEN_UNLINKED_ESTIMATES_FAILURE"
  )<{ ids: string[]; action?: string }, string[], AxiosError>()
};

export type EstimatesActions = ActionType<typeof actions>;

const initialState: State = {
  allIds: [],
  workingCopy: {},
  original: {},
  loading: false,
  loaded: false,
  allLoaded: false,
  quoteFolders: {},
  activityTotals: {},
  quoteFoldersLoaded: false,
  activityTotalsLoaded: false,
  loadEstimateErrorStatus: LoadEstimateErrorStatus.None,
  hiddenUnlinkedEstimateIds: [],
  hiddenUnlinkedEstimateIdsLoaded: false,
  heavybidDivisions: [],
  heavybidDivisionsLoaded: false,
  activityCodebookUnused: [],
  activityCodebookUnusedLoaded: false,
  activityCodebookUtilized: {},
  activityCodebookUtilizedLoaded: false,
  activityCodebookUtilizedDetails: [],
  activityCodebookUtilizedDetailsLoaded: false,
  activityCodebookMissing: [],
  activityCodebookMissingLoaded: false,
  activityCodebooksOverflowStatus: {},
  lastProcessedActivityCodebooks: []
};

// Reducer
export const reducer = (state = initialState, action: EstimatesActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadEstimates.request): {
        draft.loaded = false;
        draft.loading = true;
        draft.allLoaded = false;
        break;
      }
      case getType(actions.loadEstimates.success): {
        action.payload.forEach(estimate => {
          const normalizedEstimate = normalizeEstimateDates(estimate);
          if (!(normalizedEstimate.id in draft.original)) {
            draft.allIds.push(normalizedEstimate.id);
          }
          draft.workingCopy[normalizedEstimate.id] = normalizedEstimate;
          draft.original[normalizedEstimate.id] = normalizedEstimate;
        });
        draft.loaded = true;
        break;
      }
      case getType(actions.loadEstimates.failure): {
        draft.loading = false;
        draft.loaded = false;
        draft.allLoaded = true;
        if (action.payload.response?.status === 403) {
          draft.loaded = true;
          draft.loadEstimateErrorStatus = LoadEstimateErrorStatus.Unauthorized;
        }
        if (action.payload.response?.status === 404) {
          draft.loaded = true;
          draft.loadEstimateErrorStatus =
            LoadEstimateErrorStatus.MissingDivToBUMapping;
        }
        break;
      }
      case getType(actions.loadHeavybidDivisions.request): {
        draft.heavybidDivisionsLoaded = false;
        break;
      }
      case getType(actions.loadHeavybidDivisions.success): {
        draft.heavybidDivisions = [];
        action.payload
          .sort((p1, p2) =>
            p1.divisionCode
              .toLowerCase()
              .localeCompare(p2.divisionCode.toLowerCase())
          )
          .forEach(p =>
            draft.heavybidDivisions.push({
              partitionId: p.partitionId,
              code: p.divisionCode,
              description: p.description ?? p.divisionCode
            })
          );
        draft.heavybidDivisionsLoaded = true;
        break;
      }
      case getType(actions.loadLastProcessedActivityCodebooks.request): {
        draft.lastProcessedActivityCodebooks = [];
        break;
      }
      case getType(actions.loadLastProcessedActivityCodebooks.success): {
        draft.lastProcessedActivityCodebooks = [];
        draft.lastProcessedActivityCodebooks = action.payload;
        break;
      }
      case getType(actions.loadActivityCodebookUnused.request): {
        draft.activityCodebookUnusedLoaded = false;
        draft.activityCodebookUnused = [];
        break;
      }
      case getType(actions.loadActivityCodebookUnused.success): {
        draft.activityCodebookUnused = draft.activityCodebookUnused.concat(
          action.payload
        );
        draft.activityCodebookUnusedLoaded = true;
        break;
      }
      case getType(actions.loadActivityCodebookUnused.failure): {
        draft.activityCodebookUnusedLoaded = true;
        if (action.payload.overflow) {
          draft.activityCodebooksOverflowStatus.unusedOverflow = true;
        }
        break;
      }
      case getType(actions.loadActivityCodebookMissing.request): {
        draft.activityCodebookMissingLoaded = false;
        draft.activityCodebookMissing = [];
        break;
      }
      case getType(actions.loadActivityCodebookMissing.success): {
        draft.activityCodebookMissing = draft.activityCodebookMissing.concat(
          action.payload
        );
        draft.activityCodebookMissingLoaded = true;
        break;
      }
      case getType(actions.loadActivityCodebookMissing.failure): {
        draft.activityCodebookMissingLoaded = true;
        if (action.payload.overflow) {
          draft.activityCodebooksOverflowStatus.missingOverflow = true;
        }
        break;
      }
      case getType(actions.loadActivityCodebookUtilized.request): {
        draft.activityCodebookUtilizedLoaded = false;
        draft.activityCodebookUtilized = {};
        break;
      }
      case getType(actions.loadActivityCodebookUtilized.success): {
        action.payload.forEach(
          code => (draft.activityCodebookUtilized[code.id] = code)
        );
        draft.activityCodebookUtilizedLoaded = true;
        break;
      }
      case getType(actions.loadActivityCodebookUtilized.failure): {
        draft.activityCodebookUtilizedLoaded = true;
        if (action.payload.overflow) {
          draft.activityCodebooksOverflowStatus.utilizedOverflow = true;
        }
        break;
      }
      case getType(actions.loadActivityCodebookUtilizedDetails.request): {
        draft.activityCodebookUtilizedDetails = [];
        draft.activityCodebookUtilizedDetailsLoaded = false;
        break;
      }
      case getType(actions.loadActivityCodebookUtilizedDetails.success): {
        action.payload.forEach(activity => {
          activity.modification = ModificationTypes[activity.modification];
          draft.activityCodebookUtilizedDetails.push(activity);
        });
        draft.activityCodebookUtilizedDetailsLoaded = true;
        break;
      }
      case getType(actions.loadActivityCodebookUtilizedDetails.failure): {
        draft.activityCodebookUtilizedDetailsLoaded = true;
        break;
      }
      case getType(actions.loadedAllEstimates): {
        draft.allLoaded = true;
        break;
      }
      case getType(actions.loadQuoteFolders.success): {
        action.payload.forEach(qf => {
          draft.quoteFolders[qf.id] = qf;
        });
        draft.quoteFoldersLoaded = true;
        break;
      }
      case getType(actions.saveEstimate.request): {
        const id = action.payload.id;
        if (id) {
          setNested(action.payload, draft.workingCopy[id]);
        }
        break;
      }
      case getType(actions.saveEstimate.success): {
        draft.workingCopy[action.payload.id] = action.payload;
        draft.original[action.payload.id] = action.payload;
        const currentIndex = draft.allIds.indexOf(action.payload.id);
        if (currentIndex === -1) {
          draft.allIds.push(action.payload.id);
        }
        break;
      }
      case getType(actions.saveEstimate.failure): {
        if (action.payload.id) {
          draft.workingCopy[action.payload.id] =
            draft.original[action.payload.id];
        }
        break;
      }
      case getType(actions.loadActivityTotals.request): {
        draft.activityTotalsLoaded = false;
        break;
      }
      case getType(actions.loadActivityTotals.success): {
        const [activityTotals, formulas] = action.payload;
        const dict = mapActivityTotalsBackToFormulas(activityTotals, formulas);
        Object.values(draft.workingCopy).forEach(est => {
          est.values.customTotals = dict?.[est.id];
        });
        Object.values(draft.original).forEach(est => {
          est.values.customTotals = dict?.[est.id];
        });
        draft.activityTotalsLoaded = true;
        break;
      }
      case getType(actions.updateEstimates.success): {
        const estimates = action.payload;
        estimates.forEach(estimate => {
          const currentIndex = draft.allIds.indexOf(estimate.id);

          if (estimate.values.isDeleted) {
            if (currentIndex > -1) {
              draft.allIds.splice(currentIndex, 1);
            }
            if (estimate.id in draft.workingCopy) {
              delete draft.workingCopy[estimate.id];
            }
            if (estimate.id in draft.original) {
              delete draft.original[estimate.id];
            }
          } else {
            estimate = normalizeEstimateDates(estimate);
            draft.workingCopy[estimate.id] = estimate;
            draft.original[estimate.id] = estimate;
            if (currentIndex === -1) {
              draft.allIds.push(estimate.id);
            }
          }
        });
        draft.allIds = map(draft.workingCopy, p => p.id);
        break;
      }

      case getType(actions.loadHiddenUnlinkedEstimateIds.success): {
        const ids = action.payload;
        draft.hiddenUnlinkedEstimateIds = ids;
        draft.hiddenUnlinkedEstimateIdsLoaded = true;
        break;
      }

      case getType(actions.loadHiddenUnlinkedEstimateIds.failure): {
        draft.loading = false;
        draft.loaded = false;
        draft.hiddenUnlinkedEstimateIdsLoaded = false;
        break;
      }

      case getType(actions.patchHiddenUnlinkedEstimateIds.success): {
        const ids = action.payload;
        draft.hiddenUnlinkedEstimateIds = ids;
        break;
      }

      case getType(actions.patchHiddenUnlinkedEstimateIds.failure): {
        draft.loading = false;
        draft.loaded = false;
        break;
      }

      case getType(actions.updateHiddenEstimateIds): {
        draft.hiddenUnlinkedEstimateIds = action.payload.ids;
        break;
      }

      case getType(actions.setLoading): {
        const newLoadingState = action.payload.loadingState;
        if (newLoadingState.loaded !== undefined) {
          draft.loaded = newLoadingState.loaded;
        }
        if (newLoadingState.loading !== undefined) {
          draft.loading = newLoadingState.loading;
        }
      }
    }
  });
};

export type SelectorState = StateSlice & ViewStateSlice & SchemaStateSlice;

const getLoaded = ({ estimates }: SelectorState) => estimates.loaded;
const getLoading = ({ estimates }: SelectorState) => estimates.loading;
const getAllLoaded = ({ estimates }: SelectorState) => estimates.allLoaded;
const getQuoteFoldersLoaded = ({ estimates }: SelectorState) =>
  estimates.quoteFoldersLoaded;
const getIds = ({ estimates }: SelectorState) => estimates.allIds;
const getWorkingCopy = ({ estimates }: SelectorState) => estimates.workingCopy;
const getQuoteFolders = ({ estimates }: SelectorState) =>
  estimates.quoteFolders;
const getLoadEstimateErrorStatus = ({ estimates }: SelectorState) =>
  estimates.loadEstimateErrorStatus;
const getHiddenUnlinkedEstimateIds = ({ estimates }: SelectorState) =>
  estimates.hiddenUnlinkedEstimateIds;
const getHiddenUnlinkedEstimateIdsLoaded = ({ estimates }: SelectorState) =>
  estimates.hiddenUnlinkedEstimateIdsLoaded;
const getHeavybidDivisionsLoaded = ({ estimates }: SelectorState) =>
  estimates.heavybidDivisionsLoaded;
const getHeavybidDivisions = ({ estimates }: SelectorState) =>
  estimates.heavybidDivisions;
const getLastProcessedActivityCodebooks = ({ estimates }: SelectorState) =>
  estimates.lastProcessedActivityCodebooks;
const getAllEstimates = createSelector(
  [getIds, getWorkingCopy],
  (ids, estimates) => {
    return ids.map(id => estimates[id]);
  }
);
const getActivityCodebooksOverflowStatus = ({ estimates }: SelectorState) =>
  estimates.activityCodebooksOverflowStatus;
const getActivityCodebookUnusedLoaded = ({ estimates }: SelectorState) =>
  estimates.activityCodebookUnusedLoaded;
const getActivityCodebookUnused = ({ estimates }: SelectorState) =>
  estimates.activityCodebookUnused;
const getActivityCodebookMissingLoaded = ({ estimates }: SelectorState) =>
  estimates.activityCodebookMissingLoaded;
const getActivityCodebookMissing = ({ estimates }: SelectorState) =>
  estimates.activityCodebookMissing;

const getActivityCodebookUtilizedLoaded = ({ estimates }: SelectorState) =>
  estimates.activityCodebookUtilizedLoaded;

const getActivityCodebookUtilized = ({ estimates }: SelectorState) =>
  estimates.activityCodebookUtilized;

const getActivityCodebookUtilizedDetailsLoaded = ({
  estimates
}: SelectorState) => estimates.activityCodebookUtilizedDetailsLoaded;

const getActivityCodebookUtilizedDetails = ({ estimates }: SelectorState) =>
  estimates.activityCodebookUtilizedDetails;

const getUnlinkedEstimates = createSelector(
  [getAllEstimates, projectSelectors.getLinkedEstimates],
  (estimates, linkedEstimates) => {
    return estimates.filter(e => !linkedEstimates.has(e.id));
  }
);

const getUnlinkedAndUnhiddenEstimatesCount = createSelector(
  [
    getUnlinkedEstimates,
    getHiddenUnlinkedEstimateIds,
    getHiddenUnlinkedEstimateIdsLoaded
  ],
  (estimates, hiddenIds, hiddenIdsLoaded) => {
    if (!hiddenIdsLoaded) {
      return 0;
    }

    return estimates.filter(e => !hiddenIds.includes(e.id)).length;
  }
);

const getActiveEstimates = createSelector([getAllEstimates], estimates => {
  return estimates.filter(estimate => estimate.values.isActiveEstimate);
});

const getActiveEstimatesTrim = createSelector(
  [getActiveEstimates],
  estimates => {
    return estimates.map(estimate => estimate.values);
  }
);

const getActiveEstimatesWithoutExcluded = createSelector(
  [getActiveEstimates],
  estimates => {
    return estimates.filter(estimate => !estimate.values.isExcludedEstimate);
  }
);

const getArchivedEstimates = createSelector([getAllEstimates], estimates => {
  return estimates.filter(estimate => !estimate.values.isActiveEstimate);
});

const getArchivedEstimatesWithoutExcluded = createSelector(
  [getArchivedEstimates],
  estimates => {
    return estimates.filter(estimate => !estimate.values.isExcludedEstimate);
  }
);

const getActiveEstimateLocations = createSelector(
  [getActiveEstimatesWithoutExcluded],
  estimates => {
    const estimatesWithLocations = estimates
      .filter(
        estimate =>
          estimate.values.filters?.latitude &&
          estimate.values.filters?.longitude
      )
      .slice(0, 50);
    return estimatesWithLocations;
  }
);

const getEstimateFieldsLookup = createSelector(
  [schemaSelectors.getCompleteEstimatesExtendedSchema],
  schema => {
    if (schema) {
      return schema.fields;
    }
    return {};
  }
);

const getCurrentEstimateViewFiltersLookup = createSelector(
  [viewSelectors.getCurrentEstimateView],
  view => {
    return view?.filters ?? [];
  }
);

const getCurrentEditArchivedEstimateFiltersLookup = createSelector(
  [viewSelectors.getCurrentEditArchivedEstimateView],
  view => {
    return view?.filters ?? [];
  }
);

const getCurrentEstimateViewFiltersList = createSelector(
  [getCurrentEstimateViewFiltersLookup, getEstimateFieldsLookup],
  (filters, fields) => {
    return values(filters)
      .filter(filter => filter.columnName in fields && filter.value !== null)
      .map(filter => ({
        ...filter,
        fieldType: fields[filter.columnName].type
      }));
  }
);

const getCurrentEditArchivedEstimateViewFiltersList = createSelector(
  [getCurrentEditArchivedEstimateFiltersLookup, getEstimateFieldsLookup],
  (filters, fields) => {
    return values(filters)
      .filter(filter => filter.columnName in fields && filter.value !== null)
      .map(filter => ({
        ...filter,
        fieldType: fields[filter.columnName].type
      }));
  }
);

const getFilteredEstimates = createSelector(
  [getAllEstimates, getCurrentEstimateViewFiltersList, getEstimateFieldsLookup],
  (estimates, filters, fields) => {
    if (estimates && filters) {
      return estimates.filter(estimate => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i];
          const field = fields[filter.columnName];
          const value = get(estimate, filter.columnName);

          if (!filterField(field.type, filter, value)) {
            return false;
          }
        }
        return true;
      });
    }

    return [];
  }
);

const getFilteredEditArchivedEstimates = createSelector(
  [
    getAllEstimates,
    getCurrentEditArchivedEstimateViewFiltersList,
    getEstimateFieldsLookup
  ],
  (estimates, filters, fields) => {
    if (estimates && filters) {
      return estimates.filter(estimate => {
        if (estimate.values.isActiveEstimate) return false;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i];
          const field = fields[filter.columnName];
          const value = get(estimate, filter.columnName);

          if (!filterField(field.type, filter, value)) {
            return false;
          }
        }
        return true;
      });
    }

    return [];
  }
);

export const selectors = {
  getLoaded,
  getLoading,
  getAllLoaded,
  getQuoteFoldersLoaded,
  getHiddenUnlinkedEstimateIds,
  getAllEstimates,
  getActiveEstimates,
  getActiveEstimatesTrim,
  getActiveEstimatesWithoutExcluded,
  getArchivedEstimates,
  getFilteredEditArchivedEstimates,
  getArchivedEstimatesWithoutExcluded,
  getWorkingCopy,
  getActiveEstimateLocations,
  getQuoteFolders,
  getLoadEstimateErrorStatus,
  getIds,
  getFilteredEstimates,
  getUnlinkedEstimates,
  getUnlinkedAndUnhiddenEstimatesCount,
  getHeavybidDivisionsLoaded,
  getHeavybidDivisions,
  getActivityCodebooksOverflowStatus,
  getActivityCodebookUnused,
  getActivityCodebookUnusedLoaded,
  getActivityCodebookMissing,
  getActivityCodebookMissingLoaded,
  getActivityCodebookUtilized,
  getActivityCodebookUtilizedLoaded,
  getActivityCodebookUtilizedDetails,
  getActivityCodebookUtilizedDetailsLoaded,
  getLastProcessedActivityCodebooks
};
