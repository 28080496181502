import { all } from "redux-saga/effects";
import { sagas as accountSagas } from "./account";
import { sagas as estimatesSagas } from "./estimates";
import { sagas as projectsSagas } from "./projects";
import { sagas as schemasSagas } from "./schemas";
import { sagas as signalRSagas } from "./signalr";
import { sagas as viewsSagas } from "./views";
import { sagas as calendarsSagas } from "./calendars";
import { sagas as configSagas } from "./configurationSettings";
import { sagas as profileSagas } from "./profile";
import { sagas as contactSagas } from "./contacts";
import { sagas as emailSubscriptionSagas } from "./email-subscriptions";
import { sagas as payItemLibrarySagas } from "./quick-pricing";

export default function* rootSaga() {
  yield all([
    ...accountSagas,
    ...schemasSagas,
    ...viewsSagas,
    ...projectsSagas,
    ...signalRSagas,
    ...estimatesSagas,
    ...configSagas,
    ...calendarsSagas,
    ...profileSagas,
    ...contactSagas,
    ...emailSubscriptionSagas,
    ...payItemLibrarySagas
  ]);
}
