import { call, put, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import * as notify from "../../../core/components/notify";
import { actions } from "../state";
import { strings } from "localization";
import {
  apiSagaFactory,
  getSelectedBusinessUnitIdSaga
} from "api/api-saga-factory";
import { AxiosResponse } from "axios";
import {
  CredentialsUserDTO,
  IdentityClientsDTO
} from "api/GeneratedClients/precon";

const genericSagas = apiSagaFactory({
  apiPath: "/v1/businessUnits"
});

function* getCompanyUsers(
  action: ActionType<typeof actions.signInWithHcssCredentials.success>
) {
  try {
    const api = yield call(genericSagas.getApi);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const data: AxiosResponse<CredentialsUserDTO[]> = yield call(
      [api, api.get],
      `${businessUnitId}/company/users`
    );
    const successPayload = {
      id: action.payload.user.companyId,
      users: data.data
    };
    yield put(actions.getCompanyUsers.success(successPayload));
  } catch (error) {
    console.error(error);
    yield put(actions.getCompanyUsers.failure(error));
    notify.error(strings.users.messages.saved.error);
  }
}

function* requestClientsAfterSignIn(
  action: ActionType<typeof actions.signInWithHcssCredentials.success>
) {
  yield put(actions.getCompanyClients.request());
}

function* getCompanyClients() {
  try {
    const api = yield* genericSagas.getApi();
    const businessUnitId = yield* getSelectedBusinessUnitIdSaga();

    const data: AxiosResponse<IdentityClientsDTO[]> = yield call(
      [api, api.get],
      `${businessUnitId}/company/clients`
    );
    const successPayload = {
      clients: data.data
    };

    yield put(actions.getCompanyClients.success(successPayload));
  } catch (error) {
    console.error(error);
    yield put(actions.getCompanyClients.failure(error as Error));
    notify.error(strings.users.messages.saved.error);
  }
}

function* getBusinessUnitUsers(
  action: ActionType<typeof actions.getCompanyUsers.success>
) {
  try {
    const api = yield call(genericSagas.getApi);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const data: AxiosResponse<CredentialsUserDTO[]> = yield call(
      [api, api.get],
      `${businessUnitId}/company/users/businessUnit`
    );
    const successPayload = {
      id: businessUnitId,
      users: data.data
    };
    yield put(actions.getBusinessUnitUsers.success(successPayload));
  } catch (error) {
    console.error(error);
    yield put(actions.getBusinessUnitUsers.failure(error));
    notify.error(strings.users.messages.saved.error);
  }
}

export const sagas = [
  takeEvery(
    getType(actions.signInWithHcssCredentials.success),
    getCompanyUsers
  ),
  takeEvery(
    getType(actions.signInWithHcssCredentials.success),
    requestClientsAfterSignIn
  ),
  takeEvery(getType(actions.getCompanyClients.request), getCompanyClients),
  takeEvery(getType(actions.getCompanyUsers.success), getBusinessUnitUsers)
];
