import { isNullOrWhitespace } from "core";
import { Subscription } from "../state";
import { strings } from "localization";
import { CredentialsUserDTO } from "api/GeneratedClients/precon";

export type ValidationFieldKeys =
  | "name"
  | "projectTemplateIds"
  | "settings"
  | "recipientIds";

export type ValidationResult = Pick<
  SubscriptionValidationContext,
  "isFieldValid" | "fieldErrorMessages"
>;

type IsSubscriptionValid = Record<
  keyof Pick<Subscription, ValidationFieldKeys>,
  boolean
>;
type ValidationMessage = Record<ValidationFieldKeys, string[]>;
export interface SubscriptionValidationContext {
  isFieldValid: IsSubscriptionValid;
  newSub: Subscription;
  otherSubs: Subscription[];
  fieldErrorMessages: ValidationMessage;
  isValid: boolean;
}

const DAY_POSSIBLE_VALUES = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];
export const validateSubscription = (
  newSub: Subscription,
  otherSubs: Subscription[],
  recipients: CredentialsUserDTO[]
): SubscriptionValidationContext => {
  const context: SubscriptionValidationContext = {
    isFieldValid: {
      name: true,
      projectTemplateIds: true,
      settings: true,
      recipientIds: true
    },
    newSub: newSub,
    otherSubs: otherSubs,
    fieldErrorMessages: {
      name: [],
      projectTemplateIds: [],
      settings: [],
      recipientIds: []
    },
    isValid: true
  };
  isValidName(context);
  isValidTemplates(context);
  isValidRecipients(context, recipients);
  isValidTime(context);
  isValidTimeZone(context);
  isValidDay(context);
  Object.values(context.isFieldValid).forEach(isFieldValid => {
    context.isValid = context.isValid && isFieldValid;
  });
  return context;
};

const isValidName = (context: SubscriptionValidationContext) => {
  if (isNullOrWhitespace(context.newSub.name)) {
    context.isFieldValid.name = false;
    context.fieldErrorMessages.name.push(
      strings.emailSubscription.validation.name.hasAName
    );
  }
  const subHaveUniqueName =
    context.otherSubs.findIndex(
      otherSub =>
        otherSub.name?.toLowerCase() === context.newSub.name?.toLowerCase()
    ) === -1;
  if (!subHaveUniqueName) {
    context.isFieldValid.name = false;
    context.fieldErrorMessages.name.push(
      strings.emailSubscription.validation.name.hasUniqueName
    );
  }
};
const isValidTemplates = (context: SubscriptionValidationContext) => {
  const subHaveAtLeastOneTemplate =
    context.newSub.viewIds && context.newSub.viewIds.length > 0;
  if (!subHaveAtLeastOneTemplate) {
    context.isFieldValid.projectTemplateIds = false;
    context.fieldErrorMessages.projectTemplateIds.push(
      strings.emailSubscription.validation.templates.hasOneTemplate
    );
  }
};
const isValidRecipients = (
  context: SubscriptionValidationContext,
  recipients: CredentialsUserDTO[]
) => {
  const { recipientIds: subscriptionRecipientIds } = context.newSub;
  const subHaveAtLeastOneRecipient =
    subscriptionRecipientIds && subscriptionRecipientIds.length > 0;
  const subHasInvalidUsers = subscriptionRecipientIds.reduce((acc, curr) => {
    return recipients.find(r => r.id === curr) === undefined || acc;
  }, false);

  if (!subHaveAtLeastOneRecipient) {
    context.fieldErrorMessages.recipientIds.push(
      strings.emailSubscription.validation.recipients.hasOneRecipient
    );
  }
  if (subHasInvalidUsers) {
    context.fieldErrorMessages.recipientIds.push(
      strings.emailSubscription.validation.recipients.hasInvalidRecipients
    );
  }
  context.isFieldValid.recipientIds =
    subHaveAtLeastOneRecipient && !subHasInvalidUsers;
};
const isValidTime = (context: SubscriptionValidationContext) => {
  const newSubTime = Object.values(context.newSub.settings.scheduledTimes)[0];
  const subHaveAtLeastOneTime = newSubTime && !isNullOrWhitespace(newSubTime);
  if (!subHaveAtLeastOneTime) {
    context.isFieldValid.settings = false;
    context.fieldErrorMessages.settings.push(
      strings.emailSubscription.validation.time.hasTime
    );
  }
};
const isValidTimeZone = (context: SubscriptionValidationContext) => {
  const newSubTimeZone = context.newSub.settings.timeZoneId;
  const subHaveAtLeastOneTimeZone =
    newSubTimeZone && !isNullOrWhitespace(newSubTimeZone);
  if (!subHaveAtLeastOneTimeZone) {
    context.isFieldValid.settings = false;
    context.fieldErrorMessages.settings.push(
      strings.emailSubscription.validation.timeZone.hasTimeZone
    );
  }
};
const isValidDay = (context: SubscriptionValidationContext) => {
  const newSubDays = Object.keys(context.newSub.settings.scheduledTimes);
  const subHaveAtLeastOneDay =
    newSubDays &&
    newSubDays.length > 0 &&
    newSubDays.reduce((acc, curr) => {
      return DAY_POSSIBLE_VALUES.includes(curr) && acc;
    }, true);
  if (!subHaveAtLeastOneDay) {
    context.isFieldValid.settings = false;
    context.fieldErrorMessages.settings.push(
      strings.emailSubscription.validation.day.hasDay
    );
  }
};

export const initialValidationResult: ValidationResult = {
  isFieldValid: {
    name: true,
    projectTemplateIds: true,
    settings: true,
    recipientIds: true
  },
  fieldErrorMessages: {
    name: [],
    projectTemplateIds: [],
    settings: [],
    recipientIds: []
  }
};
