import * as React from "react";
import { Route, Switch, withRouter, useParams } from "react-router-dom";
import FullPageLoading from "../../layout/components/FullPageLoading";
import { paths } from "./paths";
import { ProtectedRoute } from "../../account/components/protected-route";
import { useSelector } from "react-redux";
import * as schemas from "modules/schemas";
import { useAuthorization } from "modules/account";
import { FeatureFlag } from "core";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";
import { QuickPricingSourceOptions } from "../components/quick-pricing/models/quick-price-sheet.model";

const ProjectsPage = React.lazy(() =>
  import(/* webpackChunkName: "project-list" */ "../components/ProjectsPage")
);

const ProjectsSetupPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-setup" */ "../components/ProjectsSetupPage"
  )
);

const HBIntegrationSetupPage = React.lazy(() =>
  import(
    /* webpackChunkName: "hb-integration-setup" */ "../components/HBIntegrationSetupPage"
  )
);

const ProjectsEmailSubscriptionSetupPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-subscription-setup" */ "../../email-subscriptions/components/ProjectsEmailSubscriptionSetupPage"
  )
);

const ProjectsDetailPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-details" */ "../components/ProjectsDetailPage"
  )
);

const ProjectsImportPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-import" */ "../components/ProjectsImportPage"
  )
);

const ProjectsImportProjectsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-import" */ "../components/ProjectsImportProjectsPage"
  )
);

const ProjectsImportBidResultsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-import" */ "../components/ProjectsImportBidResultsPage"
  )
);

const EstimateMappingPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-import" */ "../components/estimate-mapping/EstimateMappingPage"
  )
);
const ProposalSetupPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-import" */ "../../quote-management/components/quote-proposal/setup/index"
  )
);
const QuickPricingWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: "project-import" */ "../components/quick-pricing/QuickPricingPage"
  )
);

const PayItemLibraryPage = React.lazy(() =>
  import(
    /* webpackChunkName: "payitem-library" */ "../../quick-pricing/components/PayItemLibraryPage"
  )
);

export const ProjectsRouter = withRouter(() => {
  const isBidResultsAdminOnly = useSelector(
    schemas.selectors.getAllProjectSchemaFields
  )?.find(x => x.id === "bidResults")?.adminOnly;

  const auth = useAuthorization();

  const isFreeCompany =
    auth.canAccessLimitedPrecon && !auth.canAccessFullPrecon;

  const {
    isFeatureEnabled: isFrontEndEmailSubscriptionEnabled
  } = useFeatureFlags(FeatureFlag.FrontEndEmailSubscription);

  const { isFeatureEnabled: isQuickPricingEnabledPhase1 } = useFeatureFlags(
    FeatureFlag.QuickPricingPhase1
  );

  const { isFeatureEnabled: isQuickPricingEnabledPhase2 } = useFeatureFlags(
    FeatureFlag.QuickPricingPhase2
  );

  return (
    <React.Suspense fallback={<FullPageLoading loading />}>
      <Switch>
        <Route path={paths.proposal} render={() => <ProposalSetupPage />} />
        <Route
          path={paths.proposalCreate}
          render={() => <ProposalSetupPage />}
        />
        <Route
          exact
          path={paths.new}
          render={() => <ProjectsDetailPage key="create" />}
        />
        <ProtectedRoute
          isAdmin={!isFreeCompany}
          exact
          path={paths.setup}
          render={() => <ProjectsSetupPage />}
        />
        <ProtectedRoute
          isAdmin={!isFreeCompany}
          exact
          path={paths.setupHBIntegration}
          render={() => <HBIntegrationSetupPage />}
        />
        {isFrontEndEmailSubscriptionEnabled && (
          <ProtectedRoute
            isAdmin
            canAccessFullProjectTracking
            exact
            path={paths.subscriptionSetup}
            render={() => <ProjectsEmailSubscriptionSetupPage />}
          />
        )}
        {isQuickPricingEnabledPhase1 && (
          <ProtectedRoute
            isAdmin
            canAccessFullProjectTracking
            isContactAddEdit
            exact
            path={paths.payItemLibrary}
            render={() => <PayItemLibraryPage />}
          />
        )}
        <Route exact path={paths.base} render={() => <ProjectsPage />} />
        <ProtectedRoute
          isAddEdit
          exact
          path={paths.import}
          render={() => <ProjectsImportPage />}
        />
        <ProtectedRoute
          isAddEdit
          exact
          path={paths.importProjects}
          render={() => <ProjectsImportProjectsPage />}
        />
        <ProtectedRoute
          isAdmin={isBidResultsAdminOnly}
          isAddEdit={!isBidResultsAdminOnly}
          exact
          path={paths.importBidResults}
          render={() => <ProjectsImportBidResultsPage />}
        />
        <Route
          path={paths.estimateMapping}
          render={() => <EstimateMappingPage />}
        />
        <Route
          path={paths.duplicate}
          render={() => <ProjectsDetailPageWrapper isCopy />}
        />
        <ProtectedRoute
          isAddEdit
          isFullFeature
          path={paths.quickPrice.payItemLibrary}
          render={() => {
            return isQuickPricingEnabledPhase1 ? (
              <QuickPricingWrapper
                source={QuickPricingSourceOptions.PayItemLibrary}
              />
            ) : (
              <ProjectsDetailPageWrapper />
            );
          }}
        />
        <ProtectedRoute
          isAddEdit
          isFullFeature
          canAccessEstimateInsights
          path={paths.quickPrice.estimateHistory}
          render={() => {
            return isQuickPricingEnabledPhase1 &&
              isQuickPricingEnabledPhase2 ? (
              <QuickPricingWrapper
                source={QuickPricingSourceOptions.EstimateHistory}
              />
            ) : (
              <ProjectsDetailPageWrapper />
            );
          }}
        />
        <Route
          path={paths.detail}
          render={() => <ProjectsDetailPageWrapper />}
        />
      </Switch>
    </React.Suspense>
  );
});

interface ProjectsDetailPageWrapperProps {
  isCopy?: boolean;
}
const ProjectsDetailPageWrapper = ({
  isCopy
}: ProjectsDetailPageWrapperProps) => {
  const { id: projectId } = useParams<{ id: string }>();

  return <ProjectsDetailPage isCopy={isCopy} key={`${isCopy}${projectId}`} />;
};

export const Views = () => (
  <Route path={paths.base} component={ProjectsRouter} />
);

export const basePath = paths.base;
