import { apiSagaFactory } from "api/api-saga-factory";
import { takeEvery, call, put } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { DetailedPayItemLibrary, PayItemLibrary, actions } from "./state";
import * as notify from "core/components/notify";
import { strings } from "localization";
import { WithId } from "core";
import RestApi from "api/RestApi";
import { AxiosResponse } from "axios";

const genericSagas = apiSagaFactory<WithId<PayItemLibrary>>({
  isBusinessUnitApi: true,
  apiPath: "/payItemLibraries"
});

function* loadPayItemLibraries() {
  try {
    const api: RestApi<WithId<PayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<PayItemLibrary>[]> = yield call([
      api,
      api.get
    ]);
    yield put(actions.loadPayItemLibraries.success(response.data || []));
  } catch (error) {
    yield put(actions.loadPayItemLibraries.failure(error as Error));
  }
}

function* loadPayItemLibrary(
  action: ReturnType<typeof actions.loadSelectedPayItemLibrary.request>
) {
  try {
    const api: RestApi<WithId<DetailedPayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<DetailedPayItemLibrary>> = yield call(
      [api, api.get],
      `/${action.payload}`
    );
    yield put(actions.loadSelectedPayItemLibrary.success(response.data));
  } catch (error) {
    yield put(actions.loadSelectedPayItemLibrary.failure(error as Error));
  }
}
function* loadWizardPayItemLibrary(
  action: ReturnType<typeof actions.loadWizardPayItemLibrary.request>
) {
  try {
    const api: RestApi<WithId<DetailedPayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<DetailedPayItemLibrary>> = yield call(
      [api, api.get],
      `/${action.payload}`
    );
    yield put(actions.loadWizardPayItemLibrary.success(response.data));
  } catch (error) {
    yield put(actions.loadWizardPayItemLibrary.failure(error as Error));
  }
}

function* createPayItemLibrary(
  action: ActionType<typeof actions.createPayItemLibrary.request>
) {
  const newLibrary = action.payload;
  try {
    const api: RestApi<WithId<DetailedPayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<DetailedPayItemLibrary>> = yield call(
      [api, api.create],
      newLibrary
    );
    yield put(actions.createPayItemLibrary.success(response.data));
    notify.save(response.data.versionName);
  } catch (error) {
    notify.error(strings.quickPricing.notification.saveFailed);
    yield put(actions.createPayItemLibrary.failure(error as Error));
    yield put(actions.loadPayItemLibraries.failure(error as Error));
  }
}

function* deletePayItemLibrary(
  action: ReturnType<typeof actions.deletePayItemLibrary.request>
) {
  try {
    const api: RestApi<WithId<PayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    yield call([api, api.delete], `/${action.payload}`);
    yield put(actions.deletePayItemLibrary.success(action.payload));
  } catch (error) {
    yield put(actions.deletePayItemLibrary.failure(error as Error));
  }
}

export const sagas = [
  takeEvery(
    getType(actions.loadPayItemLibraries.request),
    loadPayItemLibraries
  ),
  takeEvery(
    getType(actions.createPayItemLibrary.request),
    createPayItemLibrary
  ),
  takeEvery(
    getType(actions.loadSelectedPayItemLibrary.request),
    loadPayItemLibrary
  ),
  takeEvery(
    getType(actions.loadWizardPayItemLibrary.request),
    loadWizardPayItemLibrary
  ),
  takeEvery(getType(actions.deletePayItemLibrary.request), deletePayItemLibrary)
];
