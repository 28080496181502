import { ProjectDetailsReportRequestDTO } from "api/GeneratedClients/precon";
import HeavyJobApi from "api/HeavyJobApi";
import RestApi from "api/RestApi";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { WithId } from "core";
import { strings } from "localization";
import { PreConId, getPreConIdField } from "modules/schemas/selectors";
import {
  call,
  debounce,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { LinkedEstimate, trueUpCompletedProjectIdString } from ".";
import { Project, ProjectValidationErrorDto } from "../../api";
import {
  apiSagaFactory,
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "../../api/api-saga-factory";
import * as notify from "../../core/components/notify";
import { LOCAL_PROJECT_ID } from "./components/NewProjectLink";
import {
  StateSlice,
  UndoEstimateLinkingState,
  UndoEstimateLinkingStatus,
  actions,
  selectors
} from "./state";
import {
  QuickPriceSheetCreateDto,
  QuickPriceSheetReadDto
} from "./components/quick-pricing/models/quick-price-sheet.model";

export class ProjectsApi {
  instance: AxiosInstance;
  businessUnitId: string;

  constructor(accessToken: string, businessUnitId: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.businessUnitId = businessUnitId;
  }
  unlinkEstimateFromProject = (
    projectId: string,
    projectEstimateId: string
  ) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/${projectId}/unlinkEstimateFromProject/${projectEstimateId}`;
    return this.instance.post<Project>(url);
  };

  unlinkEstimatesFromProject = (
    projectId: string,
    projectEstimateId: string[]
  ) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/${projectId}/unlinkEstimatesFromProject`;
    return this.instance.post<Project>(url, projectEstimateId);
  };

  linkEstimatesToProject = (
    projectId: string,
    projectEstimates: LinkedEstimate[]
  ) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/${projectId}/linkEstimatesToProject`;
    return this.instance.post<Project>(url, projectEstimates);
  };

  getLastPreConIdAndReserveNext = () => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/getLastPreConIdAndReserveNextId`;
    return this.instance.get<PreConId>(url);
  };

  discardReservedPreConId = (preConId: string) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/discardReservedPreConId/${preConId}`;
    return this.instance.post(url);
  };

  getLastPreConId = () => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/lastPreConId`;
    return this.instance.get<PreConId>(url);
  };

  getProjectDetailsReport = (
    projectId: string,
    body: ProjectDetailsReportRequestDTO
  ) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/reports/projects/${projectId}/projectDetails`;
    const options = {
      responseType: "blob",
      method: "POST",
      url: url,
      headers: {
        Accept: "application/pdf"
      },
      data: body
    } as AxiosRequestConfig;
    return this.instance.request(options);
  };
  createQuickPriceSheet = (quickPriceSheet: QuickPriceSheetCreateDto) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/quickPriceSheets`;
    return this.instance.post<QuickPriceSheetReadDto>(url, quickPriceSheet);
  };
}

const genericSagas = apiSagaFactory<Project>({
  isBusinessUnitApi: true,
  apiPath: "/projects",
  stateKey: "projects",
  onLoadSuccess: actions.loadProjects.success,
  onLoadFail: actions.loadProjects.failure,
  onLoadedAllRecords: actions.loadedAllRecords,
  onLoadedFirstPage: actions.loadedFirstPage
});

function* saveProject(
  action:
    | ActionType<typeof actions.saveProject.request>
    | ActionType<typeof actions.saveNewProject.request>
) {
  const isNewProject = action.type === "PROJECTS/SAVENEW_REQUEST";
  const originalProject = action.payload.project as WithId<Project>;
  const setGoToProject = action.payload.setGoToProject;
  const resetForm = action.payload.resetForm;
  const post = isNewProject || !originalProject.id;
  const silent = action.payload.meta?.silent;
  const preConIdField = yield select(getPreConIdField);
  try {
    const api: RestApi<Project> = yield call(genericSagas.getApi);
    if (action.payload.project.fields["preconId"])
      action.payload.project.fields[
        "preconId"
      ] = trueUpCompletedProjectIdString(
        action.payload.project.fields["preconId"]
      );
    const data = yield call(
      [api, post ? api.create : api.update],
      action.payload.project
    );
    const project = data.data as WithId<Project>;
    yield put(actions.saveProject.success(project));
    yield put(actions.deleteLocallyCreatedProject(LOCAL_PROJECT_ID));
    if (resetForm) {
      resetForm({ values: project.fields });
    }
    if (!silent)
      originalProject.id
        ? notify.save(originalProject.fields.name)
        : notify.add(originalProject.fields.name);
    if (post && setGoToProject) {
      setGoToProject(project.id);
    }
    const warnings: {
      message: string;
      fieldName: string;
      warningValue: string;
    }[] = project.warnings ?? [];

    warnings.forEach(warning => {
      const key = warning.message as keyof typeof strings.projects.warnings.titles;
      const title =
        strings.projects.warnings.titles[key] ||
        strings.projects.warnings.titles.default;
      notify.warning(
        title,
        strings.formatString(
          //@ts-ignore
          strings.projects.warnings.messages[warning.message],
          warning.fieldName,
          warning.warningValue
        ) as string
      );
    });
  } catch (error) {
    console.error(error);
    yield put(actions.saveProject.failure({ error, project: originalProject }));
    try {
      if (error.response.status === 409) {
        notify.error(
          `${preConIdField["name"]}: ${originalProject.fields?.["preconId"]?.["completedString"]} already exists.`
        );
      } else {
        const apiError = error.response.data
          .errors as ProjectValidationErrorDto[];
        const localizedErrorLookup = strings.import.errors
          .fieldsValidation as Record<string, string>;
        const errorMessages = apiError.map(e => {
          const { message, fieldName, invalidValue } = e;
          const errorMessage = localizedErrorLookup[message];
          return strings.formatString(
            errorMessage,
            JSON.stringify(invalidValue),
            fieldName
          ) as string;
        });

        if (errorMessages && errorMessages.length && errorMessages.length > 0) {
          notify.error(
            `Error Saving ${originalProject.fields.name}`,
            errorMessages.join(" ")
          );
        } else {
          notify.error(
            `Error Saving ${originalProject.fields.name}`,
            error.response.data.message
          );
        }
      }
    } catch {
      notify.error(`Error Saving ${originalProject.fields.name}`);
    }
  }
}

function* deleteProject(
  action: ActionType<typeof actions.deleteProject.request>
) {
  try {
    const api: RestApi<Project> = yield call(genericSagas.getApi);
    yield call([api, api.delete], action.payload);
    yield put(actions.deleteProject.success(action.payload));
    notify.remove("Project");
  } catch (error) {
    console.error(error);
    yield put(actions.deleteProject.failure(error));
  }
}

function* deleteProjects(
  action: ActionType<typeof actions.deleteProjects.request>
) {
  try {
    const api: RestApi<Project> = yield call(genericSagas.getApi);
    const response = yield call([api, api.deleteRange], action.payload);
    const data = response.data;
    yield put(actions.deleteProjects.success(data.success));
    if (data.success.length)
      notify.remove(`${response.data.success.length} Project(s)`);
    if (data.failed.length)
      notify.error(
        strings
          .formatString(strings.projects.errors.bulkDelete, data.failed.length)
          .toString()
      );
  } catch (error) {
    console.error(error);
    yield put(actions.deleteProjects.failure(error));
    notify.error(
      strings
        .formatString(strings.projects.errors.bulkDelete, action.payload.length)
        .toString()
    );
  }
}

function* linkEstimatesToProject(
  action:
    | ActionType<typeof actions.linkEstimateToProject.request>
    | ActionType<typeof actions.linkEstimatesToProject.request>
) {
  const token = yield call(getTokenSaga);
  const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
  const projectId = action.payload.projectId;
  const project = yield select(
    (state: StateSlice) => state.projects.workingCopy[projectId]
  );

  const projectEstimates = project.fields.estimates;
  const silent = action.payload.meta?.silent;
  const api = new ProjectsApi(token, businessUnitId);
  try {
    const response = yield call(
      api.linkEstimatesToProject,
      projectId,
      projectEstimates
    );
    const updatedProject: WithId<Project> = response.data;
    yield put(actions.linkEstimatesToProject.success(updatedProject));
    if (!silent) {
      notify.save(updatedProject.fields.name);
    }
  } catch (error) {
    console.error(error);
    yield put(
      actions.linkEstimatesToProject.failure({ error, projectId: projectId })
    );
    notify.error(`Error linking estimates to project ${project.fields.name}`);
  }
}

function* unlinkEstimateFromProject(
  action: ActionType<typeof actions.unlinkEstimateFromProject.request>
) {
  const token = yield call(getTokenSaga);
  const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
  const projectId = action.payload.projectId;
  const estimateId = action.payload.estimateId;
  const projectName = action.payload.projectName;

  const estimate = yield select(
    (state: StateSlice) => state.estimates.original[estimateId]
  );

  const silent = action.payload.meta?.silent;
  const api = new ProjectsApi(token, businessUnitId);
  try {
    const response = yield call(
      api.unlinkEstimateFromProject,
      projectId,
      estimateId
    );
    const updatedProject: WithId<Project> = response.data;
    yield put(actions.unlinkEstimateFromProject.success(updatedProject));
    if (!silent) {
      notify.success(
        `${projectName} Saved! - ${estimate.values.code} estimate unlinked successfully`
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      actions.unlinkEstimateFromProject.failure({ error, projectId: projectId })
    );
    notify.error(`Failed to unlink ${estimate.values.code} estimate`);
  }
}

function* waitForUndoState(...statuses: UndoEstimateLinkingStatus[]) {
  while (true) {
    const undoState: UndoEstimateLinkingState = yield select(
      selectors.getUndoEstimatesLinkingState
    );

    if (undoState.status && statuses.includes(undoState.status)) {
      return undoState;
    }

    yield take("*");
  }
}
function* undoEstimatesLinking(
  action: ActionType<typeof actions.undoEstimatesLinking>
) {
  let undoState: UndoEstimateLinkingState = yield select(
    selectors.getUndoEstimatesLinkingState
  );

  const allowedStatuses = [
    UndoEstimateLinkingStatus.Ready,
    UndoEstimateLinkingStatus.OptimisticallyReady,
    UndoEstimateLinkingStatus.Fails
  ];
  if (!undoState.status || !allowedStatuses.includes(undoState.status)) {
    return;
  } else if (
    undoState.status === UndoEstimateLinkingStatus.OptimisticallyReady
  ) {
    undoState = yield waitForUndoState(
      UndoEstimateLinkingStatus.Ready,
      UndoEstimateLinkingStatus.Fails
    );
  }

  if (undoState.projectId && undoState.linkedEstimatesIds?.length) {
    yield put(
      actions.unlinkEstimatesFromProject.request({
        projectId: undoState.projectId,
        estimateIds: undoState.linkedEstimatesIds,
        meta: {
          errorNotification:
            strings.projects.estimateMapping.errors
              .errorDuringUndoLinkingNotification
        }
      })
    );
  }
}

function* unlinkEstamesFromProject(projectId: string, estimateIds: string[]) {
  const token = yield* getTokenSaga();
  const businessUnitId = yield* getSelectedBusinessUnitIdSaga();
  let error;
  const api = new ProjectsApi(token, businessUnitId);
  const maxRetryCount = 5;
  for (let retryCount = 0; retryCount < maxRetryCount; retryCount++) {
    try {
      const apiResponse = yield call(
        api.unlinkEstimatesFromProject,
        projectId,
        estimateIds
      );

      return apiResponse.data as WithId<Project>;
    } catch (err) {
      error = err;
      if (retryCount < maxRetryCount - 1) {
        yield delay(300);
      }
    }
  }

  throw error;
}

function* unlinkEstimatesFromProject(
  action: ActionType<typeof actions.unlinkEstimatesFromProject.request>
) {
  const projectId = action.payload.projectId;
  const estimateIds = action.payload.estimateIds;

  try {
    const updatedProject = yield* unlinkEstamesFromProject(
      projectId,
      estimateIds
    );

    yield put(actions.unlinkEstimatesFromProject.success(updatedProject));
  } catch (error) {
    console.error(error);
    yield put(
      actions.unlinkEstimatesFromProject.failure({
        error: error as Error,
        projectId: projectId
      })
    );
    if (action.payload.meta?.errorNotification) {
      notify.error(action.payload.meta?.errorNotification);
    }
  }
}

function* loadHJJobs() {
  try {
    const token = yield call(getTokenSaga);
    const selectedBUId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new HeavyJobApi(token);
    const response = yield call(api.getJobs, selectedBUId);
    yield put(actions.loadHJJobs.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(actions.loadHJJobs.failure(error));
  }
}

function* loadHJJobCostTypeCostsWithRetry(jobIds?: string[]) {
  const token = yield* getTokenSaga();
  const businessUnitId = yield* getSelectedBusinessUnitIdSaga();
  let error;
  const api = new HeavyJobApi(token);
  const maxRetryCount = 5;
  for (let retryCount = 0; retryCount < maxRetryCount; retryCount++) {
    try {
      const response = yield call(
        api.getJobCostTypeCosts,
        businessUnitId,
        jobIds
      );

      return response.data;
    } catch (err) {
      error = err;
      if (retryCount < maxRetryCount - 1) {
        yield delay(300 * (3 ^ retryCount));
      }
    }
  }

  throw error;
}

function* loadHJJobCostTypeCosts(
  action: ActionType<typeof actions.loadHJJobCostTypeCosts.request>
) {
  try {
    const data = yield* loadHJJobCostTypeCostsWithRetry(action.payload.jobIds);

    yield put(actions.loadHJJobCostTypeCosts.success(data));
  } catch (error) {
    console.error(error);
    yield put(actions.loadHJJobCostTypeCosts.failure(error));
  }
}

export const sagas = [
  takeLatest(getType(actions.loadProjects.request), genericSagas.load),
  takeEvery(getType(actions.saveProject.request), saveProject),
  takeEvery(getType(actions.saveNewProject.request), saveProject),
  takeEvery(getType(actions.deleteProject.request), deleteProject),
  takeEvery(getType(actions.deleteProjects.request), deleteProjects),
  takeEvery(
    getType(actions.linkEstimateToProject.request),
    linkEstimatesToProject
  ),
  takeEvery(
    getType(actions.linkEstimatesToProject.request),
    linkEstimatesToProject
  ),
  takeEvery(
    getType(actions.unlinkEstimateFromProject.request),
    unlinkEstimateFromProject
  ),
  takeEvery(
    getType(actions.unlinkEstimatesFromProject.request),
    unlinkEstimatesFromProject
  ),
  takeEvery(getType(actions.loadHJJobs.request), loadHJJobs),
  debounce(500, actions.undoEstimatesLinking, undoEstimatesLinking),
  takeEvery(
    getType(actions.loadHJJobCostTypeCosts.request),
    loadHJJobCostTypeCosts
  )
];
