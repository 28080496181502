import {
  browserSessionPersistence,
  getAuth,
  Persistence,
  setPersistence,
  signInWithCustomToken,
  UserCredential
} from "firebase/auth";
import { GuestUserPayload, UserPayload } from "../state";
import { call } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { UserDTO, BusinessUnit } from "api/GeneratedClients/precon";

import {
  CurrentUser,
  GuestUserClaims,
  AuthenticationMethod,
  UserClaims,
  UserType,
  isSandboxEnabled
} from "core";
import { getSelectedBusinessUnitIdSaga } from "../../../api/api-saga-factory";
import { PagedResults } from "../../../api/RestApi";
import { SANDBOX_CONSTANTS } from "mirage/db/constants";

export function createGuestUser(claims: GuestUserClaims) {
  const user: CurrentUser = {
    hcssUserId: "",
    companyId: "",
    companyName: "",
    homeBusinessUnitId: "",
    homeBusinessUnitName: "",
    selectedBusinessUnitId: "",
    selectedBusinessUnitName: "",
    firstName: "",
    lastName: "",
    email: claims.email,
    type: UserType.Guest,
    //is this correct?
    accessibleBusinessUnits: [],
    isCredentialAdmin: false,
    userName: ""
  };

  return user;
}

export function createGuestUserPayload(
  user: CurrentUser,
  firUser: any,
  claims: GuestUserClaims
) {
  const payload: GuestUserPayload = {
    user,
    firUser,
    claims,
    authorization: {
      canAccessHeavyBidInsights: false,
      canAccessQuoteManagement: true,
      canAccessHcssConnect: false,
      canAccessLimitedPrecon: false,
      canAccessFullReadonlyPrecon: false,
      canAccessFullPrecon: false
    },
    type: AuthenticationMethod.OneClickLink
  };

  return payload;
}

const selectedBusinessUnit = (
  homeBusinessUnit: BusinessUnit,
  accessibleBusinessUnits: BusinessUnit[],
  userId: string
) => {
  const lsKey = `user.business.unit-${userId}`;
  const lsBusinessUnit = JSON.parse(localStorage.getItem(lsKey) || "{}");

  const selectedBU =
    accessibleBusinessUnits.find(bu => bu.id === lsBusinessUnit.id) ??
    homeBusinessUnit;

  if (selectedBU.id === homeBusinessUnit.id)
    localStorage.setItem(
      lsKey,
      JSON.stringify({ id: selectedBU.id, code: selectedBU.code })
    );

  return {
    selectedBusinessUnitId: selectedBU.id ?? "",
    selectedBusinessUnitName: selectedBU.code ?? ""
  };
};

export function createHcssUser(
  accessToken: string,
  claims: UserClaims,
  businessUnit: BusinessUnit,
  accessibleBusinessUnits: BusinessUnit[],
  userName: string
) {
  const type = claims.isSubcontractorUser
    ? UserType.Subcontractor
    : UserType.HcssSubscription;

  const {
    selectedBusinessUnitId,
    selectedBusinessUnitName
  } = selectedBusinessUnit(
    businessUnit,
    accessibleBusinessUnits,
    claims.hcssUserId
  );

  const homeBu =
    accessibleBusinessUnits.find(bu => bu.code === claims.businessUnitName) ??
    businessUnit;

  const user: CurrentUser = {
    idsrvAccessToken: accessToken,
    hcssUserId: claims.hcssUserId,
    companyId: claims.companyId,
    companyName: claims.companyName,
    homeBusinessUnitId: homeBu.id!,
    homeBusinessUnitName: homeBu.code,
    selectedBusinessUnitId,
    selectedBusinessUnitName,
    firstName: claims.firstName,
    lastName: claims.lastName,
    email: claims.email,
    type,
    accessibleBusinessUnits,
    isCredentialAdmin: claims.isCredentialAdmin,
    userName: userName
  };

  return isSandboxEnabled()
    ? createSandboxUser(
        accessToken,
        claims,
        businessUnit,
        accessibleBusinessUnits
      )
    : user;
}

function createSandboxUser(
  accessToken: string,
  claims: UserClaims,
  businessUnit: BusinessUnit,
  accessibleBusinessUnits: BusinessUnit[]
) {
  const homeBu =
    accessibleBusinessUnits.find(bu => bu.code === claims.businessUnitName) ??
    businessUnit;

  const user: CurrentUser = {
    idsrvAccessToken: accessToken,
    hcssUserId: SANDBOX_CONSTANTS.USER_ID,
    companyId: SANDBOX_CONSTANTS.COMPANY_ID,
    companyName: SANDBOX_CONSTANTS.COMPANY_NAME,
    homeBusinessUnitId: homeBu.id ?? "",
    homeBusinessUnitName: homeBu.code,
    selectedBusinessUnitId: SANDBOX_CONSTANTS.BUSINESS_UNIT_ID,
    selectedBusinessUnitName: SANDBOX_CONSTANTS.BUSINESS_UNIT_NAME,
    firstName: SANDBOX_CONSTANTS.USER_FIRST_NAME,
    lastName: SANDBOX_CONSTANTS.USER_LAST_NAME,
    email: claims.email,
    type: UserType.HcssSubscription,
    accessibleBusinessUnits,
    isCredentialAdmin: true,
    userName: SANDBOX_CONSTANTS.USER_NAME
  };
  return user;
}

export function createHcssUserPayload(
  user: CurrentUser,
  firUser: any,
  userInfo: UserDTO,
  claims: UserClaims
) {
  const { isFullUser, isLimitedUser, isBetaUser, isSubcontractorUser } = claims;

  const canAccessHeavyBidInsights = isFullUser || isLimitedUser || isBetaUser;
  const canAccessQuoteManagement = isBetaUser || isSubcontractorUser;
  const canAccessHcssConnect = isFullUser;

  const canAccessLimitedPrecon =
    isFullUser || (isLimitedUser && !userInfo.companyHasFullPreconLicenses);
  const canAccessFullReadonlyPrecon = isFullUser || isLimitedUser;
  const canAccessFullPrecon = isFullUser;

  const payload: UserPayload = {
    firUser,
    user,
    type: AuthenticationMethod.HcssCredentials,
    authorization: {
      canAccessHeavyBidInsights,
      canAccessQuoteManagement,
      canAccessHcssConnect,
      canAccessLimitedPrecon: canAccessLimitedPrecon,
      canAccessFullReadonlyPrecon: canAccessFullReadonlyPrecon,
      canAccessFullPrecon: canAccessFullPrecon
    },
    preferences: userInfo.preferences,
    permissions: userInfo.permissions
  };

  return isSandboxEnabled()
    ? createSandboxPayload(user, firUser, userInfo)
    : payload;
}

function createSandboxPayload(
  user: CurrentUser,
  firUser: any,
  userInfo: UserDTO
) {
  const payload: UserPayload = {
    firUser,
    user,
    type: AuthenticationMethod.HcssCredentials,
    authorization: {
      canAccessHeavyBidInsights: true,
      canAccessQuoteManagement: false,
      canAccessHcssConnect: false,
      canAccessLimitedPrecon: true,
      canAccessFullReadonlyPrecon: true,
      canAccessFullPrecon: true
    },
    preferences: userInfo.preferences,
    permissions: {
      admin: true,
      estimateInsights: true,
      heavyBidNumbers: true,
      write: true,
      contactWrite: true
    }
  };

  return payload;
}

export function* signinWithFirebase(token: string, getClaims: boolean) {
  const auth = getAuth();
  void setPersistence(auth, browserSessionPersistence);

  const creds: UserCredential = yield call(signInWithCustomToken, auth, token);

  const firUser = creds.user;

  if (!firUser) {
    throw new Error("Invalid Credentials: Unable to signin");
  }

  let claims: GuestUserClaims | undefined = undefined;

  if (getClaims) {
    const decoded = yield call([firUser, firUser.getIdTokenResult]);
    claims = {
      email: decoded.claims.email,
      resourceId: decoded.claims.resourceId
    };
  }

  return { firUser, claims };
}

export function* getBusinessUnits(accessToken: string) {
  const response: AxiosResponse<PagedResults<BusinessUnit[]>> = yield call(
    [axios, axios.get],
    "/api/v1/businessUnits",
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return response.data.results;
}

export function* getUserInfo(
  accessToken: string,
  userId: string,
  homeBusinessUnitCode: string
) {
  let selectedBusinessUnitId = yield call(getSelectedBusinessUnitIdSaga);
  if (!selectedBusinessUnitId || selectedBusinessUnitId.length === 0) {
    const lsBusinessUnit = JSON.parse(
      localStorage.getItem(`user.business.unit-${userId}`) || "{}"
    );
    if (lsBusinessUnit && lsBusinessUnit.id)
      selectedBusinessUnitId = lsBusinessUnit.id;
  }

  if (!selectedBusinessUnitId || selectedBusinessUnitId.length === 0) {
    const businessUnits = yield call(getBusinessUnits, accessToken);
    const selectedBusinessUnit = (businessUnits as BusinessUnit[]).find(
      bu => bu.code === homeBusinessUnitCode
    );
    if (selectedBusinessUnit) {
      selectedBusinessUnitId = selectedBusinessUnit.id;
    }
  }

  const userResponse: AxiosResponse<UserDTO> = yield call(
    [axios, axios.get],
    `/api/v1/businessUnits/${selectedBusinessUnitId}/user`,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  if (!userResponse.data) {
    throw new Error("Invalid user info response: Unable to Login");
  }

  return userResponse.data;
}
