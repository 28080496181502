import EmailSubscriptionApi from "api/EmailSubscriptionApi";
import {
  BusinessUnitReadDTO,
  CredentialsUserDTO
} from "api/GeneratedClients/precon";
import RestApi from "api/RestApi";
import {
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "api/api-saga-factory";
import { strings } from "localization";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import * as notify from "../../core/components/notify";
import {
  initialValidationResult,
  validateSubscription
} from "./common/validation-helper";
import { Subscription, actions, selectors } from "./state";

function* loadDataForSubscriptionPage() {
  yield all([call(loadTemplates), call(loadRecipients), call(loadBus)]);
  yield put(actions.loadDataForSubscriptionPage.success());
}

function* loadTemplates() {
  const accessToken = yield call(getTokenSaga);
  const buId = yield call(getSelectedBusinessUnitIdSaga);
  const TemplatesApi = new RestApi<string>(
    `/v1/businessUnits/${buId}/views/allBUs`,
    accessToken ?? ""
  );
  try {
    const templateData = yield call(TemplatesApi.get.bind(TemplatesApi));
    yield put(actions.loadTemplates.success(templateData.data));
  } catch (error) {
    yield put(actions.loadTemplates.failure(error));
  }
}

function* loadRecipients() {
  const accessToken = yield call(getTokenSaga);
  const buId = yield call(getSelectedBusinessUnitIdSaga);
  const RecipientsApi = new RestApi<CredentialsUserDTO>(
    `/v1/businessUnits/${buId}/company/allPreConUsers`,
    accessToken ?? ""
  );
  try {
    const recipientData = yield call(RecipientsApi.get.bind(RecipientsApi));
    yield put(actions.loadRecipients.success(recipientData.data));
  } catch (error) {
    yield put(actions.loadRecipients.failure(error));
  }
}

function* loadBus() {
  const accessToken = yield call(getTokenSaga);
  const BUsApi = new RestApi<BusinessUnitReadDTO>(
    `/v1/businessUnits`,
    accessToken ?? ""
  );

  try {
    const buData = yield call(BUsApi.get.bind(BUsApi));
    yield put(actions.loadBusinessUnits.success(buData.data.results));
  } catch (error) {
    yield put(actions.loadTemplates.failure(error));
  }
}

function* loadSubscriptions() {
  try {
    const accessToken = yield call(getTokenSaga);
    const api = new RestApi<Subscription>(
      `/v1/emails/projects`,
      accessToken ?? ""
    );
    const response = yield call([api, api.get]);
    yield put(actions.loadSubscriptions.success(response.data));
  } catch (error) {
    yield put(actions.loadSubscriptions.failure(error));
  }
}

function* saveSubscription(
  action: ActionType<typeof actions.saveSubscription.request>
) {
  const newSubscription = action.payload.subscription;
  const post = action.payload.isNewSubscription;
  const setValidationResult = action.payload.setValidationResult;
  const setPristineSubscription = action.payload.setPristineSubscription;
  const currentSubscriptions = yield select(selectors.getSubscriptions);
  const recipients = yield select(selectors.getRecipients);
  const { isFieldValid, fieldErrorMessages, isValid } = validateSubscription(
    newSubscription,
    currentSubscriptions?.filter((sub: any) => sub.id !== newSubscription.id),
    recipients
  );
  setValidationResult({ isFieldValid, fieldErrorMessages });
  if (!isValid) {
    const errors = Object.values(fieldErrorMessages).join("\n");
    notify.error(`${errors}`);
    return;
  }
  setValidationResult(initialValidationResult);
  try {
    const accessToken = yield call(getTokenSaga);
    const api = new RestApi<Subscription>(
      `/v1/emails/projects`,
      accessToken ?? ""
    );
    const response = yield call(
      [api, post ? api.create : api.update],
      newSubscription
    );
    const updatedSubscription = response.data;
    yield put(actions.saveSubscription.success(updatedSubscription));
    setPristineSubscription(updatedSubscription);
    notify.save(updatedSubscription.name ?? "");
  } catch (error) {
    console.error(error);
    notify.error(strings.emailSubscription.notification.saveFailed);
    yield put(actions.saveSubscription.failure(error));
  }
}

function* pauseSubscription(
  action: ActionType<typeof actions.pauseSubscription.request>
) {
  const subscriptionId = action.payload.subscriptionId;
  try {
    const accessToken = yield call(getTokenSaga);
    const api = new EmailSubscriptionApi(accessToken);
    const response = yield call(api.pauseSubscription, subscriptionId);
    const updatedSubscription = response.data;
    yield put(actions.pauseSubscription.success());
    notify.save(updatedSubscription.name ?? "");
  } catch (error) {
    console.error(error);
    notify.error(strings.emailSubscription.notification.saveFailed);
    yield put(actions.pauseSubscription.failure(error));
  }
}

function* resumeSubscription(
  action: ActionType<typeof actions.resumeSubscription.request>
) {
  const subscriptionId = action.payload.subscriptionId;
  try {
    const accessToken = yield call(getTokenSaga);
    const api = new EmailSubscriptionApi(accessToken);
    const response = yield call(api.resumeSubscription, subscriptionId);
    const updatedSubscription = response.data;
    yield put(actions.resumeSubscription.success());
    notify.save(updatedSubscription.name ?? "");
  } catch (error) {
    console.error(error);
    notify.error(strings.emailSubscription.notification.saveFailed);
    yield put(actions.resumeSubscription.failure(error));
  }
}

function* deleteSubscription(
  action: ActionType<typeof actions.deleteSubscription.request>
) {
  const deletingSubscription = action.payload.deletingSubscription;
  const accessToken = yield call(getTokenSaga);
  const api = new RestApi<Subscription>(
    `/v1/emails/projects`,
    accessToken ?? ""
  );
  try {
    yield call([api, api.delete], deletingSubscription.id);
    notify.remove(strings.emailSubscription.notification.subscription);
    yield put(
      actions.deleteSubscription.success({
        deletingId: deletingSubscription.id
      })
    );
  } catch (error) {
    console.error(error);
    notify.error(strings.emailSubscription.notification.deleteFailed);
    yield put(actions.saveSubscription.failure(error));
  }
}

export const sagas = [
  takeLatest(
    getType(actions.loadDataForSubscriptionPage.request),
    loadDataForSubscriptionPage
  ),
  takeEvery(getType(actions.saveSubscription.request), saveSubscription),
  takeEvery(getType(actions.deleteSubscription.request), deleteSubscription),
  takeEvery(getType(actions.pauseSubscription.request), pauseSubscription),
  takeEvery(getType(actions.resumeSubscription.request), resumeSubscription),
  takeEvery(getType(actions.loadSubscriptions.request), loadSubscriptions)
];
