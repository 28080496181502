import { CompanyEntity } from "api";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";
import produce from "immer";
import { WithId } from "core";
import { PayItem, PayItemLibraryCreateDto } from "./models";
import { createSelector } from "reselect";
export interface PayItemLibrary extends CompanyEntity {
  ownerId: string;
  ownerCode: string;
  versionName: string;
}

export interface DetailedPayItemLibrary extends PayItemLibrary {
  payItems: PayItem[];
}

export interface DetailedPayItemLibrary extends PayItemLibrary {
  payItems: PayItem[];
}

export interface PayItemLibraryMap {
  [key: string]: PayItem;
}
export const STATE_KEY = "payItemLibraries";

export interface StateSlice {
  [STATE_KEY]: State;
}

export interface State {
  allIds: string[];
  workingCopy: { [key: string]: WithId<PayItemLibrary> };
  original: { [key: string]: WithId<PayItemLibrary> };
  selectedLibrary?: WithId<DetailedPayItemLibrary>;
  isLibrariesLoaded: boolean;
  isSelectedLibraryLoaded: boolean;
  selectedWizardLibrary?: WithId<DetailedPayItemLibrary>;
  selectedWizardLibraryLoading: boolean;
}

export type PayItemLibraryActions = ActionType<typeof actions>;

export const actions = {
  loadPayItemLibraries: createAsyncAction(
    "PAYITEMLIBRARIES/LOAD_DATA_REQUEST",
    "PAYITEMLIBRARIES/LOAD_DATA_SUCCESS",
    "PAYITEMLIBRARIES/LOAD_DATA_FAILURE"
  )<void, WithId<PayItemLibrary>[], Error>(),
  createPayItemLibrary: createAsyncAction(
    "PAYITEMLIBRARIES/CREATE_REQUEST",
    "PAYITEMLIBRARIES/CREATE_SUCCESS",
    "PAYITEMLIBRARIES/CREATE_FAILURE"
  )<PayItemLibraryCreateDto, WithId<DetailedPayItemLibrary>, Error>(),
  loadSelectedPayItemLibrary: createAsyncAction(
    "PAYITEMLIBRARY/LOAD_DATA_REQUEST",
    "PAYITEMLIBRARY/LOAD_DATA_SUCCESS",
    "PAYITEMLIBRARY/LOAD_DATA_FAILURE"
  )<string, WithId<DetailedPayItemLibrary>, Error>(),
  loadWizardPayItemLibrary: createAsyncAction(
    "WIZARDPAYITEMLIBRARY/LOAD_DATA_REQUEST",
    "WIZARDPAYITEMLIBRARY/LOAD_DATA_SUCCESS",
    "WIZARDPAYITEMLIBRARY/LOAD_DATA_FAILURE"
  )<string, WithId<DetailedPayItemLibrary>, Error>(),
  deletePayItemLibrary: createAsyncAction(
    "PAYITEMLIBRARY/DELETE_REQUEST",
    "PAYITEMLIBRARY/DELETE_SUCCESS",
    "PAYITEMLIBRARY/DELETE_FAILURE"
  )<string, string, Error>()
};

const initialState: State = {
  allIds: [],
  workingCopy: {},
  original: {},
  isLibrariesLoaded: false,
  isSelectedLibraryLoaded: true,
  selectedWizardLibraryLoading: false
};

export const reducer = (
  state = initialState,
  action: PayItemLibraryActions
) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadPayItemLibraries.request): {
        draft.isLibrariesLoaded = false;
        break;
      }
      case getType(actions.loadPayItemLibraries.failure): {
        draft.isLibrariesLoaded = true;
        break;
      }
      case getType(actions.loadPayItemLibraries.success): {
        action.payload.forEach(library => {
          if (!(library.id in draft.original)) {
            draft.allIds.push(library.id);
          }
          draft.workingCopy[library.id] = library;
          draft.original[library.id] = library;
        });
        draft.isLibrariesLoaded = true;
        break;
      }
      case getType(actions.createPayItemLibrary.request): {
        draft.isLibrariesLoaded = false;
        draft.isSelectedLibraryLoaded = false;
        break;
      }
      case getType(actions.createPayItemLibrary.failure): {
        draft.isLibrariesLoaded = true;
        draft.isSelectedLibraryLoaded = true;
        break;
      }
      case getType(actions.createPayItemLibrary.success): {
        const library = action.payload;
        if (!(library.id in draft.original)) {
          draft.allIds.push(library.id);
        }
        const { payItems, ...slimLibrary } = library;

        draft.workingCopy[library.id] = slimLibrary;
        draft.original[library.id] = slimLibrary;
        draft.selectedLibrary = library;
        draft.isLibrariesLoaded = true;
        draft.isSelectedLibraryLoaded = true;
        break;
      }
      case getType(actions.loadSelectedPayItemLibrary.request): {
        draft.selectedLibrary = {
          ...draft.workingCopy[action.payload],
          payItems: []
        };
        draft.isSelectedLibraryLoaded = false;
        break;
      }
      case getType(actions.loadSelectedPayItemLibrary.failure): {
        draft.isSelectedLibraryLoaded = true;
        break;
      }
      case getType(actions.loadSelectedPayItemLibrary.success): {
        const library = action.payload;
        draft.selectedLibrary = library;
        draft.isSelectedLibraryLoaded = true;
        break;
      }
      case getType(actions.deletePayItemLibrary.request): {
        draft.isSelectedLibraryLoaded = false;
        break;
      }
      case getType(actions.deletePayItemLibrary.failure): {
        draft.isSelectedLibraryLoaded = true;
        break;
      }
      case getType(actions.deletePayItemLibrary.success): {
        const libraryId = action.payload;
        draft.allIds = draft.allIds.filter(id => id !== libraryId);
        delete draft.workingCopy[libraryId];
        delete draft.original[libraryId];
        if (draft.selectedLibrary?.id === libraryId) {
          draft.selectedLibrary = undefined;
        }
        draft.isSelectedLibraryLoaded = true;
        break;
      }
      case getType(actions.loadWizardPayItemLibrary.request): {
        draft.selectedWizardLibraryLoading = true;
        draft.selectedWizardLibrary = undefined;
        break;
      }
      case getType(actions.loadWizardPayItemLibrary.failure): {
        draft.selectedWizardLibraryLoading = false;
        break;
      }
      case getType(actions.loadWizardPayItemLibrary.success): {
        const library = action.payload;
        draft.selectedWizardLibrary = library;
        draft.selectedWizardLibraryLoading = false;
        break;
      }
    }
  });
};

export type SelectorState = StateSlice;

// Selectors
const getLibrariesLoaded = ({ payItemLibraries }: SelectorState) =>
  payItemLibraries.isLibrariesLoaded;
const getAllIds = ({ payItemLibraries }: SelectorState) =>
  payItemLibraries.allIds;
const getLibraries = ({ payItemLibraries }: StateSlice) =>
  Object.values(payItemLibraries.workingCopy);
const getSelectedLibrary = ({ payItemLibraries }: StateSlice) =>
  payItemLibraries.selectedLibrary;
const getSelectedLibraryLoaded = ({ payItemLibraries }: StateSlice) =>
  payItemLibraries.isSelectedLibraryLoaded;
const getSelectedWizardLibrary = ({ payItemLibraries }: StateSlice) =>
  payItemLibraries.selectedWizardLibrary;
const getSelectedWizardLibraryLoading = ({ payItemLibraries }: StateSlice) =>
  payItemLibraries.selectedWizardLibraryLoading;
const getSelectedWizardLibraryMap = createSelector(
  [getSelectedWizardLibrary],
  library => {
    const map: PayItemLibraryMap = {};
    library?.payItems.forEach(payItem => (map[payItem.code] = payItem));
    return map;
  }
);

export const selectors = {
  getLibrariesLoaded,
  getAllIds,
  getLibraries,
  getSelectedLibrary,
  getSelectedLibraryLoaded,
  getSelectedWizardLibraryLoading,
  getSelectedWizardLibraryMap
};
