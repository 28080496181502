import rfdc from "rfdc";
import produce from "immer";
import { keyBy, values, flatten, isEqual } from "lodash-es";
import { createSelector } from "reselect";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType
} from "typesafe-actions";
import {
  Project,
  SchemaFieldType,
  EstimateEntity,
  SchemaField,
  JobDto,
  FieldMetadata,
  PreConPermissions,
  JobCostTypeCostDto
} from "api";
import { CurrentUser, WithId } from "core";
import { filterField } from "modules/schemas/filters";
import * as views from "modules/views";
import * as schemas from "modules/schemas";
import * as config from "modules/configurationSettings";
import * as account from "modules/account";
import * as calendars from "modules/calendars";
import * as estimates from "modules/estimates";
import {
  constantSections,
  populateHJJobFieldsWithValues,
  populateProjectWithBidResults,
  populateProjectWithEstimateValues,
  populateProjectWithLastModifiedBy,
  PreConId,
  selectEstimatesFields
} from "./common";
import { createUserDictFromUserList } from "modules/account";
import {
  CredentialsUserDTO,
  IdentityClientsDTO
} from "api/GeneratedClients/precon";
import { ProjectEstimate } from "api/GeneratedClients/precon";
import { calculatedFieldService } from "modules/schemas/services/calculated-field.service";

const fastDeepClone = rfdc();
export const STATE_KEY = "projects";

// Models
export enum UndoEstimateLinkingStatus {
  InProgress = "InProgress",
  Fails = "Fails",
  Success = "Success",
  Ready = "Ready",
  OptimisticallyReady = "OptimisticallyReady"
}

export interface UndoEstimateLinkingState {
  status?: UndoEstimateLinkingStatus;
  projectId?: string;
  linkedEstimatesIds?: string[];
  error?: Error;
}
export interface State {
  allIds: string[];
  workingCopy: { [key: string]: WithId<Project> };
  original: { [key: string]: WithId<Project> };
  loading: boolean;
  loaded: boolean;
  allLoaded: boolean;
  //this flag is meant to handle our current approach of loading projects
  // load the first 100, then load the rest. we need a flag to enable users to
  // see their 100 most recent projects to make the site "feel" much faster
  loadedFirstPage: boolean;
  errors: string[];
  selectedViewId?: string;
  hJJobs: JobDto[];
  undoEstimateLinkingState: UndoEstimateLinkingState;
  hJJobCostTypeCosts: Record<string, JobCostTypeCostDto>;
  hJJobCostTypeCostsLoading: boolean;
  hJJobCostTypeCostsLoadingError?: Error;
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadProjects: createAsyncAction(
    "PROJECTS/LOAD_REQUEST",
    "PROJECTS/LOAD_SUCCESS",
    "PROJECTS/LOAD_FAILURE"
  )<void, WithId<Project>[], Error>(),

  loadPreConID: createAsyncAction(
    "PROJECTS/LOAD_PRECONID_REQUEST",
    "PROJECTS/LOAD_PRECONID_REQUEST",
    "PROJECTS/LOAD_PRECONID_REQUEST"
  )<void, PreConId | undefined | null, Error>(),

  saveProject: createAsyncAction(
    "PROJECTS/SAVE_REQUEST",
    "PROJECTS/SAVE_SUCCESS",
    "PROJECTS/SAVE_FAILURE"
  )<
    {
      project: Project;
      meta?: { silent: boolean };
      setGoToProject?: (value: React.SetStateAction<string>) => void;
      resetForm?: (value: any) => void;
    },
    WithId<Project>,
    { error: Error; project: Project }
  >(),

  saveNewProject: createAsyncAction(
    "PROJECTS/SAVENEW_REQUEST",
    "PROJECTS/SAVENEW_SUCCESS",
    "PROJECTS/SAVENEW_FAILURE"
  )<
    {
      project: WithId<Project>;
      meta?: { silent: boolean };
      setGoToProject?: (value: React.SetStateAction<string>) => void;
      resetForm?: (value: any) => void;
    },
    WithId<Project>,
    { error: Error; project: Project }
  >(),

  deleteProject: createAsyncAction(
    "PROJECTS/DELETE_REQUEST",
    "PROJECTS/DELETE_SUCCESS",
    "PROJECTS/DELETE_FAILURE"
  )<string, string, Error>(),

  deleteProjects: createAsyncAction(
    "PROJECTS/DELETE_MULTIPLE_REQUEST",
    "PROJECTS/DELETE_MULTIPLE_SUCCESS",
    "PROJECTS/DELETE_MULTIPLE_FAILURE"
  )<string[], string[], Error>(),
  updateProjects: createAction("PROJECTS/UPDATE", resolve => {
    return (projects: WithId<Project>[]) => resolve({ projects });
  }),

  setSelectedViewId: createAction("PROJECTS/SET_VIEW_ID", resolve => {
    return (selectedViewId: string) => resolve({ selectedViewId });
  }),

  loadedAllRecords: createAction("PROJECTS/LOAD_ALL_COMPLETE", resolve => {
    return () => resolve();
  }),

  loadedFirstPage: createAction(
    "PROJECTS/LOAD_MOST_RECENT_COMPLETE",
    resolve => {
      return () => resolve();
    }
  ),

  linkEstimateToProject: createAsyncAction(
    "PROJECTS/LINK_ESTIMATE_REQUEST",
    "PROJECTS/LINK_ESTIMATE_SUCCESS",
    "PROJECTS/LINK_ESTIMATE_FAILURE"
  )<
    {
      projectId: string;
      estimateId: string;
      meta?: {
        silent: boolean;
      };
    },
    WithId<Project>,
    { error: Error; projectId: string }
  >(),

  unlinkEstimateFromProject: createAsyncAction(
    "PROJECTS/UNLINK_ESTIMATE_REQUEST",
    "PROJECTS/UNLINK_ESTIMATE_SUCCESS",
    "PROJECTS/UNLINK_ESTIMATE_FAILURE"
  )<
    {
      projectId: string;
      estimateId: string;
      projectName: string;
      meta?: {
        silent: boolean;
      };
    },
    WithId<Project>,
    { error: Error; projectId: string }
  >(),

  undoEstimatesLinking: createAction("PROJECTS/UNDO_ESTIMATES_LINKING"),

  resetUndoEstimatesLinking: createAction(
    "PROJECTS/RESET_UNDO_ESTIMATES_LINKING"
  ),

  unlinkEstimatesFromProject: createAsyncAction(
    "PROJECTS/UNLINK_ESTIMATES_REQUEST",
    "PROJECTS/UNLINK_ESTIMATES_SUCCESS",
    "PROJECTS/UNLINK_ESTIMATES_FAILURE"
  )<
    {
      projectId: string;
      estimateIds: string[];
      meta?: {
        errorNotification: string;
      };
    },
    WithId<Project>,
    { error: Error; projectId: string }
  >(),

  linkEstimatesToProject: createAsyncAction(
    "PROJECTS/LINK_ESTIMATES_TO_PROJECT_REQUEST",
    "PROJECTS/LINK_ESTIMATES_TO_PROJECT_SUCCESS",
    "PROJECTS/LINK_ESTIMATES_TO_PROJECT_FAILURE"
  )<
    {
      projectId: string;
      projectEstimates: LinkedEstimate[];
      meta?: {
        silent: boolean;
      };
    },
    WithId<Project>,
    { error: Error; projectId: string }
  >(),

  loadHJJobs: createAsyncAction(
    "HJJob/LOAD_REQUEST",
    "HJJob/LOAD_SUCCESS",
    "HJJob/LOAD_FAILURE"
  )<void, JobDto[], Error>(),

  loadHJJobCostTypeCosts: createAsyncAction(
    "HJJobCostTypeCost/LOAD_REQUEST",
    "HJJobCostTypeCost/LOAD_SUCCESS",
    "HJJobCostTypeCost/LOAD_FAILURE"
  )<{ jobIds?: string[] }, JobCostTypeCostDto[], Error>(),

  updateProjectFieldMetadata: createAction(
    "PROJECTS/UPDATE_PROJECT_FIELD_METADATA",
    resolve => {
      return (
        projectId: string,
        fieldId: string,
        projectFieldMetadata: FieldMetadata
      ) => resolve({ projectId, fieldId, projectFieldMetadata });
    }
  ),

  updateProjectMetadata: createAction(
    "PROJECTS/UPDATE_PROJECT_METADATA",
    resolve => {
      return (
        projectId: string,
        projectMetadata: { [key: string]: FieldMetadata }
      ) => resolve({ projectId, projectMetadata });
    }
  ),
  createNewProjectLocally: createAction(
    "PROJECTS/CREATE_LOCAL_PROJECT",
    resolve => {
      return (newLocalProject: WithId<Project>) => resolve({ newLocalProject });
    }
  ),
  deleteLocallyCreatedProject: createAction(
    "PROJECTS/DELETE_LOCAL_PROJECT",
    resolve => {
      return (deletingProjectID: string) => resolve({ deletingProjectID });
    }
  )
};

export type ProjectsActions = ActionType<typeof actions>;

const initialState: State = {
  allIds: [],
  workingCopy: {},
  original: {},
  loading: false,
  loaded: false,
  allLoaded: false,
  loadedFirstPage: false,
  errors: [],
  hJJobs: [],
  undoEstimateLinkingState: {},
  hJJobCostTypeCosts: {},
  hJJobCostTypeCostsLoading: false
};

// Reducer
export const reducer = (state = initialState, action: ProjectsActions) => {
  return produce(state, draft => {
    const enableUndoEstimateLinking = (
      projectId: string,
      linkedEstimate: string[]
    ) => {
      const originalProject = draft.original[projectId];

      const originalEstimatesIds = (
        (originalProject &&
          (originalProject.fields["estimates"] as ProjectEstimate[])) ||
        []
      ).map(e => e.id);

      draft.undoEstimateLinkingState = {
        projectId: projectId,
        linkedEstimatesIds: linkedEstimate.filter(
          id => !originalEstimatesIds.includes(id)
        ),
        error: undefined,
        status: UndoEstimateLinkingStatus.OptimisticallyReady
      };
    };

    const updateUndoEstimateLinkingStatus = (
      projectId: string,
      status: UndoEstimateLinkingStatus,
      error: Error | undefined = undefined
    ) => {
      if (draft.undoEstimateLinkingState.projectId !== projectId) {
        return;
      }

      draft.undoEstimateLinkingState = {
        ...draft.undoEstimateLinkingState,
        error: error,
        status: status
      };
    };

    const saveProjectSuccess = (updatedProject: WithId<Project>) => {
      const project = moveLastModifiedDate(updatedProject);
      draft.original[project.id] = project;
      draft.workingCopy[project.id] = project;
      const currentIndex = draft.allIds.indexOf(project.id);
      if (currentIndex === -1) {
        draft.allIds.push(project.id);
      }
    };

    const saveProjectFailure = (projectId: string | undefined) => {
      if (projectId) {
        draft.workingCopy[projectId] = draft.original[projectId];
      }
    };

    const unlinkEstimateFromProject = (
      projectId: string,
      projectEstimateId: string
    ) => {
      const currentProject = draft.workingCopy[projectId];
      const currentLinkedEstimates = currentProject.fields?.["estimates"];
      if (!currentLinkedEstimates) return;
      draft.workingCopy[projectId].fields[
        "estimates"
      ] = currentLinkedEstimates.filter((e: any) => e.id !== projectEstimateId);
    };

    const unlinkEstimatesFromProject = (
      projectId: string,
      projectEstimateId: string[]
    ) => {
      const currentProject = draft.workingCopy[projectId];
      const currentLinkedEstimates = currentProject.fields?.["estimates"];
      if (!currentLinkedEstimates) return;
      draft.workingCopy[projectId].fields[
        "estimates"
      ] = currentLinkedEstimates.filter(
        (e: any) => !projectEstimateId.includes(e.id)
      );
    };

    const linkEstimatesToProjectRequest = (
      projectId: string,
      projectEstimates: LinkedEstimate[]
    ) => {
      const currentProject = draft.workingCopy[projectId];
      const currentLinkedEstimates = currentProject.fields?.["estimates"];
      if (!currentLinkedEstimates) {
        draft.workingCopy[projectId].fields["estimates"] = [];
      }
      if (projectEstimates) {
        draft.workingCopy[projectId].fields["estimates"] = projectEstimates;
      }
    };

    switch (action.type) {
      case getType(actions.loadProjects.request): {
        draft.allLoaded = false;
        draft.loaded = false;
        draft.loading = true;
        break;
      }
      case getType(actions.loadProjects.success): {
        action.payload.forEach(project => {
          project = moveLastModifiedDate(project);
          if (!(project.id in draft.original)) {
            draft.allIds.push(project.id);
          }
          draft.workingCopy[project.id] = project;
          draft.original[project.id] = project;
        });
        draft.loading = false;
        draft.loaded = true;
        break;
      }
      case getType(actions.loadProjects.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error loading projects: ${action.payload.message}`);
        break;
      }

      case getType(actions.loadedAllRecords): {
        draft.loading = false;
        draft.loaded = true;
        draft.allLoaded = true;
        break;
      }

      case getType(actions.loadedFirstPage): {
        draft.loadedFirstPage = true;
        break;
      }

      case getType(actions.loadPreConID.failure): {
        draft.errors.push(`Error fetching PreCon Id`);
        break;
      }

      case getType(actions.saveProject.request): {
        draft.loading = true;
        break;
      }
      //TODO: Make me distinct from saveProject
      case getType(actions.saveNewProject.request): {
        const estimates: ProjectEstimate[] =
          action.payload.project.fields["estimates"] || [];
        enableUndoEstimateLinking(
          action.payload.project.id,
          estimates.map(e => e.id)
        );

        const id = action.payload.project.id;
        if (!draft.workingCopy[id])
          draft.workingCopy[id] = { ...action.payload.project, id };
        break;
      }
      case getType(actions.saveProject.success): {
        draft.loading = false;
        updateUndoEstimateLinkingStatus(
          action.payload.id,
          UndoEstimateLinkingStatus.Ready
        );
        saveProjectSuccess(action.payload);
        break;
      }
      case getType(actions.saveProject.failure): {
        draft.loading = false;
        saveProjectFailure(action.payload.project.id);
        break;
      }
      case getType(actions.deleteProject.success): {
        delete draft.workingCopy[action.payload];
        delete draft.original[action.payload];
        draft.allIds = Object.keys(draft.original).map(
          key => draft.original[key].id
        );
        break;
      }
      case getType(actions.deleteProjects.success): {
        action.payload.forEach(id => {
          delete draft.workingCopy[id];
          delete draft.original[id];
        });
        draft.allIds = Object.keys(draft.original).map(
          key => draft.original[key].id
        );
        break;
      }
      case getType(actions.updateProjects): {
        const { projects } = action.payload;
        //if a single project changes, the state will be updated anyway
        // so lets run the isEqual check below until we found a single project change.
        let noProjectsChanged = true;
        projects.forEach(project => {
          project = moveLastModifiedDate(project);
          const currentIndex = draft.allIds.indexOf(project.id);
          if (project.deleted || project.archived) {
            if (currentIndex > -1) {
              draft.allIds.splice(currentIndex, 1);
            }
            if (project.id in draft.workingCopy) {
              delete draft.workingCopy[project.id];
            }
            if (project.id in draft.original) {
              delete draft.original[project.id];
            }
          } else {
            //this is an optimization to prevent some expensive selectors from refiring unnecessarily
            // Right now, a given client is receiving its own signalR updates, so state is being updated twice
            const currProjectAlreadyUpdated = noProjectsChanged
              ? isEqual(project, draft.workingCopy[project.id])
              : false;
            if (!currProjectAlreadyUpdated) {
              draft.workingCopy[project.id] = project;
              draft.original[project.id] = project;
              if (currentIndex === -1) {
                draft.allIds.push(project.id);
              }
              noProjectsChanged = false;
            }
          }
        });
        break;
      }
      case getType(actions.setSelectedViewId): {
        draft.selectedViewId = action.payload.selectedViewId;
        break;
      }
      case getType(actions.linkEstimateToProject.request): {
        enableUndoEstimateLinking(action.payload.projectId, [
          action.payload.estimateId
        ]);
        const currentProject = draft.workingCopy[action.payload.projectId];
        const currentLinkedEstimates = currentProject.fields?.["estimates"];

        let projectEstimates: LinkedEstimate[] = [];
        const newProjectEstimate: LinkedEstimate = {
          id: action.payload.estimateId,
          selectedEstimate: true
        };
        if (!currentLinkedEstimates) {
          projectEstimates.push(newProjectEstimate);
        } else {
          if (
            !currentLinkedEstimates.find(
              (x: any) => x.id === action.payload.estimateId
            )
          ) {
            projectEstimates = currentLinkedEstimates;
            projectEstimates.push(newProjectEstimate);
          }
        }

        linkEstimatesToProjectRequest(
          action.payload.projectId,
          projectEstimates
        );
        break;
      }
      case getType(actions.linkEstimateToProject.success): {
        updateUndoEstimateLinkingStatus(
          action.payload.id,
          UndoEstimateLinkingStatus.Ready
        );
        saveProjectSuccess(action.payload);
        break;
      }
      case getType(actions.linkEstimateToProject.failure): {
        draft.undoEstimateLinkingState = {};
        saveProjectFailure(action.payload.projectId);
        break;
      }

      case getType(actions.unlinkEstimateFromProject.request): {
        unlinkEstimateFromProject(
          action.payload.projectId,
          action.payload.estimateId
        );
        break;
      }
      case getType(actions.unlinkEstimateFromProject.success): {
        saveProjectSuccess(action.payload);
        break;
      }
      case getType(actions.unlinkEstimateFromProject.failure): {
        saveProjectFailure(action.payload.projectId);
        break;
      }

      case getType(actions.unlinkEstimatesFromProject.request): {
        updateUndoEstimateLinkingStatus(
          action.payload.projectId,
          UndoEstimateLinkingStatus.InProgress
        );
        unlinkEstimatesFromProject(
          action.payload.projectId,
          action.payload.estimateIds
        );
        break;
      }
      case getType(actions.unlinkEstimatesFromProject.success): {
        updateUndoEstimateLinkingStatus(
          action.payload.id,
          UndoEstimateLinkingStatus.Success
        );
        saveProjectSuccess(action.payload);
        break;
      }
      case getType(actions.unlinkEstimatesFromProject.failure): {
        updateUndoEstimateLinkingStatus(
          action.payload.projectId,
          UndoEstimateLinkingStatus.Fails,
          action.payload.error
        );
        saveProjectFailure(action.payload.projectId);
        break;
      }

      case getType(actions.linkEstimatesToProject.request): {
        const estimateIds = action.payload.projectEstimates.map(e => e.id);
        enableUndoEstimateLinking(action.payload.projectId, estimateIds);

        linkEstimatesToProjectRequest(
          action.payload.projectId,
          action.payload.projectEstimates
        );
        break;
      }
      case getType(actions.linkEstimatesToProject.success): {
        updateUndoEstimateLinkingStatus(
          action.payload.id,
          UndoEstimateLinkingStatus.Ready
        );
        saveProjectSuccess(action.payload);
        break;
      }
      case getType(actions.linkEstimatesToProject.failure): {
        draft.undoEstimateLinkingState = {};
        saveProjectFailure(action.payload.projectId);
        break;
      }
      case getType(actions.loadHJJobs.success): {
        const jobs = action.payload;
        draft.hJJobs = jobs;
        break;
      }
      case getType(actions.loadHJJobs.failure): {
        draft.hJJobs = [];
        break;
      }
      case getType(actions.resetUndoEstimatesLinking): {
        draft.undoEstimateLinkingState = {};
        break;
      }
      case getType(actions.updateProjectFieldMetadata): {
        const projectId = action.payload.projectId;
        const fieldId = action.payload.fieldId;
        const newFieldMetadata = action.payload.projectFieldMetadata;

        if (!draft.workingCopy[projectId].fieldsMetadata) {
          draft.workingCopy[projectId].fieldsMetadata = {};
        }
        draft.workingCopy[projectId].fieldsMetadata[fieldId] = newFieldMetadata;
        break;
      }
      case getType(actions.updateProjectMetadata): {
        const projectId = action.payload.projectId;
        const newProjectMetadata = action.payload.projectMetadata;
        if (!draft.workingCopy[projectId].fieldsMetadata) {
          draft.workingCopy[projectId].fieldsMetadata = {};
        }
        draft.workingCopy[projectId].fieldsMetadata = newProjectMetadata;
        break;
      }
      case getType(actions.createNewProjectLocally): {
        const newLocalProject = action.payload.newLocalProject;
        draft.workingCopy[newLocalProject.id] = newLocalProject;
        break;
      }
      case getType(actions.deleteLocallyCreatedProject): {
        const deletingProjectId = action.payload.deletingProjectID;
        delete draft.workingCopy[deletingProjectId];
        delete draft.original[deletingProjectId];
        break;
      }
      case getType(actions.loadHJJobCostTypeCosts.request): {
        draft.hJJobCostTypeCostsLoading = true;
        break;
      }
      case getType(actions.loadHJJobCostTypeCosts.success): {
        const jobs = action.payload;

        for (const job of jobs) {
          draft.hJJobCostTypeCosts[job.jobId] = job;
        }

        draft.hJJobCostTypeCostsLoading = false;
        break;
      }
      case getType(actions.loadHJJobCostTypeCosts.failure): {
        draft.hJJobCostTypeCostsLoadingError = action.payload;
        draft.hJJobCostTypeCostsLoading = false;
        break;
      }
    }
  });
};

const moveLastModifiedDate = (project: WithId<Project>) => {
  project.fields.lastModified = project.lastModified;
  project.fields.lastModifiedByUserId = project.lastModifiedByUserId;
  return project;
};

export type SelectorState = StateSlice &
  views.SelectorState &
  schemas.StateSlice &
  config.StateSlice &
  account.StateSlice &
  estimates.StateSlice;

// Selectors
const getAllIds = ({ projects }: SelectorState) => projects.allIds;
const getLoaded = ({ projects }: SelectorState) => projects.loaded;
const getProjectHash = ({ projects }: SelectorState) => projects.workingCopy;
const getLoading = ({ projects }: SelectorState) => projects.loading;
const getProjectHashPristine = ({ projects }: SelectorState) =>
  projects.original;
const getEstimateHash = ({ estimates }: SelectorState) => estimates.workingCopy;
const getAllEstimatesLoaded = ({ estimates }: SelectorState) =>
  estimates.allLoaded;
const getAllProjectsLoaded = ({ projects }: SelectorState) =>
  projects.allLoaded;
const getFirstPageLoaded = ({ projects }: SelectorState) =>
  projects.loadedFirstPage;
const getErrors = ({ projects }: SelectorState) => projects.errors;
const getHJJobs = ({ projects }: SelectorState) => projects.hJJobs;
const getUndoEstimatesLinkingState = ({ projects }: SelectorState) =>
  projects.undoEstimateLinkingState;
const getHJJobCostTypeCosts = ({ projects }: SelectorState) =>
  projects.hJJobCostTypeCosts;
const getHJJobCostTypeCostsLoading = ({ projects }: SelectorState) =>
  projects.hJJobCostTypeCostsLoading;

const getProjectFieldsLookup = createSelector(
  [schemas.selectors.getCompleteProjectSchema],
  schema => {
    if (schema) {
      return schema.fields;
    }
    return {};
  }
);

const getProjectSectionsList = createSelector(
  [schemas.selectors.getProjectSchema],
  schema => {
    if (schema) {
      return schema.orderedSections;
    }
    return [];
  }
);

const getProjectSectionsLookup = createSelector(
  [schemas.selectors.getProjectSchema],
  schema => {
    if (schema) {
      return schema.sections;
    }
    return {};
  }
);

const getOrderedProjectSections = createSelector(
  [getProjectSectionsLookup, getProjectSectionsList],
  (sectionLookup, sectionList) => {
    return sectionList.map(sectionId => sectionLookup[sectionId]);
  }
);

const getProjectFields = createSelector(
  [getProjectFieldsLookup],
  fieldsLookup => {
    return values(fieldsLookup);
  }
);

const getFilterableProjectFields = createSelector([getProjectFields], fields =>
  fields.filter(field => !field.hiddenInTable)
);

const getFilterableProjectFieldsLookup = createSelector(
  [getFilterableProjectFields],
  fields => keyBy(fields, "id")
);

const getProjectFieldsBySection = createSelector(
  [getOrderedProjectSections, getProjectFieldsLookup],
  (sections, fieldsLookup) => {
    return sections.map(section => {
      return {
        ...section,
        fields: section.fields.map(fieldId => fieldsLookup[fieldId])
      };
    });
  }
);

const getFilterableProjectFieldsBySection = createSelector(
  [getOrderedProjectSections, getFilterableProjectFieldsLookup],
  (sections, fieldsLookup) => {
    return sections.map(section => {
      return {
        ...section,
        fields: section.fields
          .filter(fieldId => fieldId in fieldsLookup)
          .map(fieldId => fieldsLookup[fieldId])
      };
    });
  }
);

const getFilterableProjectFieldsOrdered = createSelector(
  [getFilterableProjectFieldsBySection],

  sections => {
    return flatten(sections.map(section => section.fields));
  }
);

export const getEstimateFields = createSelector(
  [getProjectFields],
  selectEstimatesFields
);

const getEstimateTotalRelatedFieldIds = createSelector(
  [getProjectFields],
  fields => {
    return fields
      .filter(field => {
        const isEstimateField =
          field.lookup &&
          (field.id.includes("values.totals") ||
            field.id.includes("values.customTotals"));

        const calculatedFieldConfig = calculatedFieldService.getConfig(field);
        const isCalculatedFieldWithEstimates =
          field.type === SchemaFieldType.Calculated &&
          calculatedFieldService.hasEstimatesVariables(calculatedFieldConfig);

        return isEstimateField || isCalculatedFieldWithEstimates;
      })
      .map(field => field.id);
  }
);
const getFilterableFields = createSelector([getProjectFields], fields => {
  return fields.filter(field => {
    if (field.hiddenInTable) {
      return false;
    }

    switch (field.type) {
      case SchemaFieldType.ShortText:
        return true;
      case SchemaFieldType.Boolean:
        return true;
      case SchemaFieldType.Number:
        return true;
      case SchemaFieldType.Currency:
        return true;
      case SchemaFieldType.Date:
        return true;
      case SchemaFieldType.DateTime:
        return true;
      case SchemaFieldType.List:
        return true;
      case SchemaFieldType.PreConId:
        return true;
      default:
        return false;
    }
  });
});

const getSelectableFields = createSelector([getFilterableFields], fields => {
  return fields.filter(
    field =>
      field.type !== SchemaFieldType.Number &&
      field.type !== SchemaFieldType.Currency
  );
});

export const getProjectsLookup = createSelector(
  [getAllProjectsLoaded, getFirstPageLoaded, getProjectHash],
  (loaded, firstPageLoaded, hash) => {
    if (loaded || firstPageLoaded) return hash;
    else return false;
  }
);

export const getEstimatesLookup = createSelector(
  [getAllEstimatesLoaded, getEstimateHash],
  (loaded, hash) => {
    if (loaded) return hash;
    else return false;
  }
);

export interface LinkedEstimate {
  selectedEstimate?: boolean;
  id: string;
}

const getLinkedEstimates = createSelector(getProjectHash, projectsHash => {
  const result = new Map<string, LinkedEstimate[]>();

  if (!projectsHash) {
    return result;
  }

  const projects = Object.values(projectsHash).filter(
    p => p.fields?.estimates?.length
  );

  for (const project of projects) {
    const projectEstimates = project.fields.estimates;

    for (const estimate of projectEstimates as LinkedEstimate[]) {
      const linkedEstimates = result.get(estimate.id);

      if (linkedEstimates) {
        linkedEstimates.push(estimate);
      } else {
        result.set(estimate.id, [estimate]);
      }
    }
  }

  return result;
});

export const enrichProjectWithEstimates = (
  project: Pick<Project, "fields">,
  estimateLookup: Record<string, WithId<EstimateEntity>>,
  projectFields: SchemaField[]
) => {
  //get selectedEstimates for project as list of estimates
  const selectedEstimates: WithId<EstimateEntity>[] =
    project.fields.estimates
      ?.filter((estimate: LinkedEstimate) => {
        if (estimate.selectedEstimate && estimate.id in estimateLookup) {
          const selectedEstimate = estimateLookup[estimate.id];
          if (selectedEstimate?.values?.code) {
            return true;
          }
        }

        return false;
      })
      .map((estimate: LinkedEstimate) => estimateLookup[estimate.id]) ?? [];
  project.fields.estimates = project.fields.estimates?.map(
    (est: { id: string; selected?: boolean }) => ({
      ...est,
      code: estimateLookup[est.id]?.values?.code
    })
  );
  const estimateFields = selectEstimatesFields(projectFields);

  estimateFields.forEach(estimateField => {
    populateProjectWithEstimateValues(
      project,
      estimateField,
      selectedEstimates
    );
  });
};

export const enrichProjectWithTableLookupFields = (
  project: Pick<Project, "fields">,
  projectFields: SchemaField[]
) => {
  const tableLookupFields = projectFields.filter(
    f => f.config.tableLookupField
  );

  for (const field of tableLookupFields) {
    const columnId = field.config.columnId;

    const value: Record<string, number>[] =
      project.fields[field.config.tableFieldId];

    if (!value) {
      continue;
    }

    project.fields[field.id] = value.reduce(
      (acc, curr) => acc + +(curr[columnId as string] || 0),
      0
    );
  }
};

export const enrichProject = (
  project: WithId<Project>,
  [
    users,
    clients,
    permissions,
    currentUser,
    estimateLookup,
    jobs,
    projectFields
  ]: [
    Record<string, CredentialsUserDTO>,
    Record<string, IdentityClientsDTO>,
    PreConPermissions,
    Readonly<CurrentUser>,
    Record<string, WithId<EstimateEntity>>,
    JobDto[],
    SchemaField[]
  ]
) => {
  const projectCopy = fastDeepClone(project);

  //get last modified by
  populateProjectWithLastModifiedBy(projectCopy, users, clients);

  //get bid results
  populateProjectWithBidResults(projectCopy, currentUser);

  //get job code/description
  populateHJJobFieldsWithValues(projectCopy, jobs);

  enrichProjectWithEstimates(projectCopy, estimateLookup, projectFields);

  enrichProjectWithTableLookupFields(projectCopy, projectFields);

  const calculatedFields = projectFields.filter(
    p => p.type === SchemaFieldType.Calculated
  );
  calculatedFieldService.setCalculateFields(
    calculatedFields,
    projectCopy,
    permissions.estimateInsights
  );

  return projectCopy;
};

const selectAccountProjectData = createSelector(
  [
    account.selectors.getPermissions,
    account.selectors.getHcssUser,
    account.selectors.getCompanyUsers,
    account.selectors.getCompanyClients
  ],
  (permissions, currentUser, companyUsers, identityClients) => ({
    permissions,
    currentUser,
    companyUsers,
    identityClients
  })
);

export const getAllProjectsWithEstimates = createSelector(
  [
    getProjectsLookup,
    getEstimatesLookup,
    getProjectFields,
    selectAccountProjectData,
    getHJJobs
  ],
  (
    projectHash,
    estimateLookup,
    projectFields,
    { permissions, currentUser, companyUsers, identityClients },
    jobs
  ) => {
    if (!permissions || !currentUser || companyUsers.length < 1) {
      return [];
    }
    if (!projectHash || !estimateLookup) {
      return Object.values(projectHash);
    }
    const users = createUserDictFromUserList(companyUsers);
    const clients: Record<string, IdentityClientsDTO> = identityClients.reduce(
      (acc, client) => ({ ...acc, [client.clientId]: { ...client } }),
      {}
    );

    return Object.values(projectHash).map(originalProject =>
      enrichProject(originalProject, [
        users,
        clients,
        permissions,
        currentUser,
        estimateLookup,
        jobs,
        projectFields
      ])
    );
  }
);

const getProjectEstimateMapping = createSelector(
  [getAllProjectsWithEstimates],
  projects => {
    const mapping: Record<string, WithId<Project>[]> = {};
    projects.forEach(project => {
      const linkedEstimates = project.fields?.estimates ?? [];
      linkedEstimates.forEach((linkedEstimate: LinkedEstimate) => {
        if (linkedEstimate.id) {
          if (!mapping[linkedEstimate.id]) {
            mapping[linkedEstimate.id] = [];
          }
          mapping[linkedEstimate.id].push(project);
        }
      });
    });
    return mapping;
  }
);

const getCurrentProjectViewFiltersLookup = createSelector(
  [views.selectors.getCurrentProjectView],
  view => {
    return view?.filters ?? [];
  }
);

const getCurrentProjectViewFiltersList = createSelector(
  [
    getCurrentProjectViewFiltersLookup,
    getProjectFieldsLookup,
    schemas.selectors.isPreConIdEnabled
  ],
  (filters, fields, isPreConIdEnabled) => {
    return values(filters)
      .filter(filter => {
        if (filter.columnName === "preconId" && !isPreConIdEnabled)
          return false;
        return filter.columnName in fields && filter.value !== null;
      })
      .map(filter => ({
        ...filter,
        fieldType: fields[filter.columnName].type
      }));
  }
);

const getIsProjectsFilteredByEstimateTotals = createSelector(
  [getCurrentProjectViewFiltersList, getEstimateTotalRelatedFieldIds],
  (filters, fieldIds) => {
    for (const filter of filters) {
      const isFilterValueEmpty =
        filter.value?.from === undefined && filter.value?.to === undefined;

      if (fieldIds.includes(filter.columnName) && !isFilterValueEmpty)
        return true;
    }
    return false;
  }
);

const getCurrentProjectViewFields = createSelector(
  [views.selectors.getCurrentProjectView, getProjectFields],
  (view, fields) => {
    if (view && fields) {
      return fields.filter(field => view.displayedColumns.includes(field.id));
    }
    return [];
  }
);

const getFilteredProjects = createSelector(
  [
    getAllProjectsWithEstimates,
    getCurrentProjectViewFiltersList,
    getProjectFieldsLookup
  ],
  (projects, filters, fields) => {
    if (projects && filters) {
      return projects.filter(project => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i];
          const field = fields[filter.columnName];
          const value = project.fields[filter.columnName];
          if (!filterField(field.type, filter, value)) {
            return false;
          }
        }
        return true;
      });
    }

    return [];
  }
);

const getSelectableFieldList = createSelector([getSelectableFields], fields => {
  return fields
    .map(column => ({ display: column.name, value: column.id }))
    .sort((a, b) =>
      a.display.localeCompare(b.display, undefined, { sensitivity: "base" })
    );
});

const getShareableFieldsFromProjectSection = createSelector(
  [getProjectFieldsBySection],
  sections => {
    const firstSection = sections.filter(
      s => !constantSections.includes(s.id)
    )[0];

    if (!firstSection) {
      return [];
    }

    return firstSection.fields
      .reduce((result: SchemaField[], curr) => {
        if (
          curr.type !== SchemaFieldType.Estimates &&
          curr.type !== SchemaFieldType.BidResults &&
          curr.type !== SchemaFieldType.Links &&
          curr.type !== SchemaFieldType.Checklist
        ) {
          result.push(curr);
        }
        return result;
      }, [])
      .filter(field => !field.hiddenInTable);
  }
);

export const selectors = {
  getAllIds,
  getProjectSchema: schemas.selectors.getProjectSchema,
  getProjectEstimateMapping,
  getProjectFieldsLookup,
  getProjectHash,
  getProjectHashPristine,
  getCurrentProjectViewFields,
  getCurrentProjectView: views.selectors.getCurrentProjectView,
  getCurrentProjectViewClean: views.selectors.getCurrentProjectViewClean,
  getAllProjectsWithEstimates,
  getFilterableFields,
  getSelectableFields,
  getCurrentProjectViewFiltersList,
  getLoaded,
  getLoading,
  getProjectFieldsBySection,
  getFilteredProjects,
  getFilterableProjectFieldsBySection,
  getCalendarSubscribeIsActive: calendars.selectors.getCalendarPanelActive,
  getFilterableProjectFieldsOrdered,
  getSelectableFieldList,
  getShareableFieldsFromProjectSection,
  getIsProjectsFilteredByEstimateTotals,
  getErrors,
  getAllProjectLoadedStatus: getAllProjectsLoaded,
  getFirstPageLoaded,
  getHJJobs,
  getLinkedEstimates,
  getUndoEstimatesLinkingState,
  getHJJobCostTypeCosts,
  getHJJobCostTypeCostsLoading
};
